import ConnectButton from 'components/connect-button';
import PatronNftImage from '../../components/PatronNftImage';
import { AppRoutes } from 'components/router';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'stores';

export const LogInPage = () => {
  const navigate = useNavigate();
  const session = useUserStore((state) => state.session);

  if (session) {
    navigate(AppRoutes.HOME);
  }

  return (
    <main className="flex w-full flex-col items-center justify-between">
      <div className="from-canvas via-canvas to-none relative z-50 flex flex-col gap-y-10 bg-gradient-to-b from-80% p-10">
        <div className="flex flex-col items-center gap-y-6">
          <h1 className="title-4xl-semibold">Patron Portal</h1>
          <p className="body-lg-normal">
            Welcome to the official Infinex Patron Portal application.
          </p>
        </div>
        <div className="flex flex-col items-center gap-y-8">
          <p className="body-base-normal text-slate11">
            Connect the wallet associated with your Patrons to continue.
          </p>
          <ConnectButton />
        </div>
      </div>
      <div>
        <PatronNftImage />
      </div>
    </main>
  );
};
