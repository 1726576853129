import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';
import type { SelectProps as RACSelectProps } from 'react-aria-components';
import {
  Button,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select as RACSelect,
  SelectValue,
} from 'react-aria-components';
import { CheckIcon, ChevronDownIcon } from '../../icons';
import { cn } from '../../utils';

const selectVariants = cva(
  'bg-shark4 group-focus:border-interactiveActive hover:border-interactiveHover text-default disabled:border-interactiveDisabled disabled:bg-disabled invalid:border-interactiveCritical group relative flex h-full w-full min-w-max select-none items-center justify-between gap-x-2 rounded-md border border-[transparent] p-3 font-medium outline-none transition-colors',
  {
    variants: {
      size: {
        small: 'h-8',
        medium: 'h-10',
        large: 'h-12',
      },
    },
  }
);

const buttonTextVariants = cva('', {
  variants: {
    size: {
      small: 'body-sm-medium',
      medium: 'body-sm-medium',
      large: 'body-base-medium',
    },
  },
});

type Item = {
  name: string;
  value: string;
  icon?: string;
};

export interface SelectProps
  extends VariantProps<typeof selectVariants>,
    RACSelectProps<Item> {
  items: Item[];
  label?: string;
}

export const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  ({ items, size = 'medium', label, ...props }, ref) => {
    return (
      <RACSelect className="group w-full min-w-28" ref={ref} {...props}>
        {label && (
          <Label className="text-secondary body-sm-medium mb-2 flex leading-none">
            {label}
          </Label>
        )}

        <Button className={cn(selectVariants({ size }))}>
          <SelectValue
            className={cn(
              'group-disabled:text-disabled data-[placeholder="true"]:text-subtle flex items-center gap-x-3',
              buttonTextVariants({ size })
            )}
          />
          <ChevronDownIcon className="group-disabled:fill-disabled size-5" />
        </Button>

        <Popover className="bg-shark4 entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out w-[--trigger-width] overflow-auto rounded-md border text-base shadow-lg backdrop-blur-lg backdrop-filter">
          <ListBox className="outline-none" items={items}>
            {(item) => (
              <ListBoxItem
                id={item.value}
                textValue={item.name}
                className="text-default hover:bg-neutralHover data-[focused=true]:bg-neutralHover body-sm-medium flex cursor-pointer items-center justify-between gap-x-3 p-3 outline-none transition-colors duration-150"
              >
                {({ isSelected }) => (
                  <>
                    <div className="flex items-center gap-x-3">
                      {item.icon && (
                        <img
                          src={new URL(item.icon, import.meta.url).href}
                          className="inline-flex size-5"
                        />
                      )}

                      <span>{item.name}</span>
                    </div>

                    {isSelected && (
                      <CheckIcon
                        aria-hidden="true"
                        className="text-accent size-5"
                      />
                    )}
                  </>
                )}
              </ListBoxItem>
            )}
          </ListBox>
        </Popover>
      </RACSelect>
    );
  }
);

Select.displayName = 'Select';
