import { ChainAsset } from '@infinex/asset-config';
import { type Connection, PublicKey } from '@solana/web3.js';

import { AssetBalance } from '../types/balance';

const TOKEN_PROGRAM_ID = 'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA';

export async function getSolanaTokenBalance({
  connection,
  accountAddress,
  token,
}: {
  connection: Connection;
  accountAddress: PublicKey;
  token: { mint: PublicKey };
}) {
  const balance = await connection.getParsedTokenAccountsByOwner(
    accountAddress,
    token
  );

  const value =
    balance?.value[0]?.account?.data?.parsed?.info?.tokenAmount?.amount;

  return bigIntOrZero(value);
}

export async function getSplAssetBalances({
  connection,
  accountAddress,
  splSupportedChainAssets,
}: {
  connection: Connection;
  accountAddress: PublicKey;
  splSupportedChainAssets: ChainAsset[];
}): Promise<AssetBalance[]> {
  const accounts = await connection.getParsedTokenAccountsByOwner(
    accountAddress,
    { programId: new PublicKey(TOKEN_PROGRAM_ID) }
  );

  if (!accounts.value || accounts?.value?.length === 0) return [];

  const mintAmounts: {
    address: string | undefined;
    amount: string | undefined;
  }[] = accounts.value.map((a) => ({
    address: a?.account?.data?.parsed?.info?.mint,
    amount: a?.account?.data?.parsed?.info?.tokenAmount?.amount,
  }));

  return splSupportedChainAssets.map((a) => {
    const amount = mintAmounts.find(
      (ma) => ma.address === a.address.toString()
    )?.amount;

    return {
      ...a,
      address: a.address.toString(),
      balance: bigIntOrZero(amount),
    };
  });
}

function bigIntOrZero(value: string | undefined) {
  return value ? BigInt(value) : 0n;
}
