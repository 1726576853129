import { LinkButton } from '@infinex/ui/components';

import { AppRoutes } from 'components/router';

export const NotFound = () => {
  return (
    <section className="flex flex-grow py-6">
      <div className="mx-auto flex max-w-6xl flex-grow flex-col items-center justify-center gap-y-8 text-center">
        <h1 className="title-3xl-semibold md:title-4xl-semibold xl:title-5xl-semibold">
          Page not found
        </h1>

        <LinkButton href={AppRoutes.ROOT}>Go to Home</LinkButton>
      </div>
    </section>
  );
};
