import {
  type ZonedDateTime,
  getLocalTimeZone,
  now,
  startOfYear,
} from '@internationalized/date';
import type { DateFormatterOptions } from 'react-aria';

// Constants
// ------------------------------

export const TIME_PERIODS = {
  '1h': {
    label: '1 hour',
    compactLabel: '1h',
    interval: 60,
    tickInterval: 60 * 10,
  },
  '1d': {
    label: '1 day',
    compactLabel: '1D',
    interval: 60 * 10,
    tickInterval: 60 * 60,
  },
  '1w': {
    label: '1 week',
    compactLabel: '1W',
    interval: 60 * 60,
    tickInterval: 60 * 60 * 24,
  },
  '1m': {
    label: '1 month',
    compactLabel: '1M',
    interval: 60 * 60 * 6,
    tickInterval: 60 * 60 * 24,
  },
  '3m': {
    label: '3 months',
    compactLabel: '3M',
    interval: 60 * 60 * 24,
    tickInterval: 60 * 60 * 24 * 2,
  },
  '6m': {
    label: '6 months',
    compactLabel: '6M',
    interval: 60 * 60 * 24,
    tickInterval: 60 * 60 * 24 * 3,
  },
  ytd: {
    label: 'Year to date',
    compactLabel: 'YTD',
    interval: 60 * 60 * 24,
    tickInterval: 60 * 60 * 24 * 3,
  },
  '1y': {
    label: '1 year',
    compactLabel: '1Y',
    interval: 60 * 60 * 24 * 2,
    tickInterval: 60 * 60 * 24 * 7,
  },
  all: {
    label: 'All',
    compactLabel: 'All',
    interval: 60 * 60 * 24 * 7, // pretty naive, "all" could be one month or ten years. really want to avoid making this dynamic though…
    tickInterval: 60 * 60 * 24 * 30,
  },
};

export const PERIOD_ITEMS = Object.entries(TIME_PERIODS).map((entry) => {
  const [key, props] = entry;
  return { key: key as PeriodKey, ...props };
}) as {
  key: PeriodKey;
  label: string;
  compactLabel: string;
  interval: number;
}[];

export const DEFAULT_PERIODS = ['1d', '1w', '1m', '6m', '1y', 'all'] as const;

// Types
// ------------------------------

export type PeriodKey = keyof typeof TIME_PERIODS;

// Utils
// ------------------------------

export function getRangeConstraints(period: PeriodKey): {
  key: PeriodKey;
  start: string;
  end: string;
  interval: number;
  tickInterval: number;
} {
  const timeNow = now(getLocalTimeZone());
  const withStart = (start: ZonedDateTime) => ({
    start: start.toAbsoluteString(),
    end: timeNow.toAbsoluteString(),
    key: period,
    interval: TIME_PERIODS[period].interval,
    tickInterval: TIME_PERIODS[period].tickInterval,
  });

  switch (period) {
    case '1h':
      return withStart(timeNow.subtract({ hours: 1 }));
    case '1d':
      return withStart(timeNow.subtract({ days: 1 }));
    case '1w':
      return withStart(timeNow.subtract({ weeks: 1 }));
    case '1m':
      return withStart(timeNow.subtract({ months: 1 }));
    case '3m':
      return withStart(timeNow.subtract({ months: 3 }));
    case '6m':
      return withStart(timeNow.subtract({ months: 6 }));
    case 'ytd':
      return withStart(startOfYear(timeNow));
    case '1y':
      return withStart(timeNow.subtract({ years: 1 }));
    case 'all':
      return withStart(timeNow.subtract({ years: 10 })); // maybe "max" is more appropriate?
    default: {
      const exhaustiveCheck: never = period;
      throw new Error(`Invalid period: "${exhaustiveCheck}"`);
    }
  }
}

export function getDateFormatterOptions(
  period: PeriodKey
): DateFormatterOptions {
  switch (period) {
    case '1h':
    case '1d':
      return { hour: 'numeric', minute: '2-digit', hour12: true };
    case '1w':
      return { month: 'short', day: 'numeric' };
    case '1m':
    case '3m':
    case '6m':
    case 'ytd':
      return { month: 'short', day: 'numeric' };
    case '1y':
    case 'all':
      return { year: 'numeric', month: 'short', day: 'numeric' };
    default: {
      const exhaustiveCheck: never = period;
      throw new Error(`Invalid period: "${exhaustiveCheck}"`);
    }
  }
}
