import { createIcon } from '../createIcon';

export const ProgressPendingIcon = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19.848 13.561a8.039 8.039 0 0 0 0-3.122l1.471-.291a9.536 9.536 0 0 1 0 3.704l-1.471-.29Zm-1.195-6.006 1.246-.834a9.545 9.545 0 0 0-2.62-2.62l-.834 1.246a8.043 8.043 0 0 1 2.207 2.208ZM13.56 4.152l.291-1.471a9.54 9.54 0 0 0-3.704 0l.29 1.471a8.04 8.04 0 0 1 3.123 0ZM7.555 5.347l-.834-1.246a9.544 9.544 0 0 0-2.62 2.62l1.246.834a8.044 8.044 0 0 1 2.208-2.208ZM4.152 10.44a8.04 8.04 0 0 0 0 3.122l-1.471.291a9.54 9.54 0 0 1 0-3.704l1.471.29Zm1.195 6.006-1.246.834a9.545 9.545 0 0 0 2.62 2.62l.834-1.247a8.043 8.043 0 0 1-2.208-2.207Zm5.092 3.403-.291 1.471a9.536 9.536 0 0 0 3.704 0l-.29-1.471a8.039 8.039 0 0 1-3.123 0Zm6.006-1.195.834 1.246a9.547 9.547 0 0 0 2.62-2.62l-1.247-.834a8.042 8.042 0 0 1-2.207 2.207Z"
  />,
  'ProgressPendingIcon'
);
