import { forwardRef } from 'react';
import { ArrowOutwardIcon, ChevronRightIcon } from '../../icons';
import { cn } from '../../utils';
import { Link, LinkProps } from './Link';

export interface ChevronLinkProps
  extends Omit<LinkProps, 'children' | 'target'> {
  href: string;
  label: string;
  isExternalLink?: boolean;
}

export const ChevronLink = forwardRef<HTMLAnchorElement, ChevronLinkProps>(
  ({ className, isExternalLink, label, ...props }, ref) => (
    <Link
      ref={ref}
      {...props}
      className={cn(
        'inline-flex flex-nowrap items-center justify-center gap-x-1 pl-4 pr-3',
        'text-secondary border-subtle h-10 rounded-md border outline-none transition-all',
        'hover:bg-surfaceTwo ring-offset-2 focus-visible:ring-1',
        className
      )}
      target={isExternalLink ? '_blank' : undefined}
    >
      <span className="body-sm-medium">{label}</span>
      {isExternalLink ? (
        <ArrowOutwardIcon className="size-5" />
      ) : (
        <ChevronRightIcon className="size-5" />
      )}
    </Link>
  )
);

ChevronLink.displayName = 'ChevronLink';
