import { filterDOMProps } from '@react-aria/utils';
import type { DOMProps } from '@react-types/shared';
import { type ForwardedRef, forwardRef } from 'react';

import { type StyleProps, cn } from '../../utils';

export type SkeletonProps = {
  /**
   * Compose layouts representing your content, with components `SkeletonText`,
   * `SkeletonParagraph`, and `SkeletonMedia`.
   */
  children?: React.ReactNode;
} & DOMProps &
  StyleProps;

/**
 * Display a placeholder of your content before data is loaded to indicate
 * that something is happening and preview the content that will appear.
 */
export const Skeleton = forwardRef(
  (props: SkeletonProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, className, style, ...otherProps } = props;
    return (
      <div
        className={cn('animate-pulse cursor-progress', className)}
        ref={ref}
        style={style}
        {...filterDOMProps(otherProps)}
      >
        {children}
        <span className="sr-only">Loading…</span>
      </div>
    );
  }
);
