import { CustomAssetMetadata } from '../types/metadata';
import { UNKNOWN_ASSET_METADATA } from '.';

export async function getSolanaTokenMetadata({
  address,
  heliusApiUrl,
}: {
  address: string;
  heliusApiUrl: string;
}): Promise<CustomAssetMetadata> {
  if (!heliusApiUrl) return UNKNOWN_ASSET_METADATA;
  const res = await fetch(heliusApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      jsonrpc: '2.0',
      id: address,
      method: 'getAsset',
      params: {
        id: address,
        options: {
          showFungible: true,
        },
      },
    }),
  });

  const data = (await res.json()) as any;
  if (data.error) {
    console.warn('Failed to fetch metadata for', address, data.error);
    return UNKNOWN_ASSET_METADATA;
  }

  const { name, symbol } =
    data.result?.content?.metadata ?? UNKNOWN_ASSET_METADATA;
  const decimals = data.result?.token_info?.decimals ?? undefined;

  return {
    name: name ?? 'UNKNOWN',
    symbol: symbol?.toUpperCase() ?? 'UNKNOWN',
    decimals,
  };
}
