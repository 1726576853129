export const screenValues = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const screens = Object.fromEntries(
  Object.entries(screenValues).map(([key, value]) => [key, `${value}px`])
);
