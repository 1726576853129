import React, { useId } from 'react';
import { ProgressBar } from 'react-aria-components';
import { cn } from '../../utils';

interface ProgressPortion {
  value: number;
  color: string;
}

interface MultiProgressBarRadialProps {
  portions: ProgressPortion[];
  strokeWidth?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  children?: React.ReactNode;
  className?: string;
  isPulsing?: boolean;
  bgStrokeColor?: string;
}

export const MultiProgressBarRadial = ({
  portions,
  strokeWidth = 2,
  size = 'md',
  children,
  className,
}: MultiProgressBarRadialProps) => {
  const sizeClassMap = {
    xs: 'size-8',
    sm: 'size-12',
    md: 'size-16',
    lg: 'size-24',
    xl: 'size-40',
    '2xl': 'size-48',
    '3xl': 'size-64',
  };

  const sizeClass = sizeClassMap[size] || sizeClassMap['md'];

  // SVG Id
  const randomId = useId();

  // Increase the viewBox size to accommodate the glow
  const viewBoxSize = 48;
  const center = viewBoxSize / 2;
  const r = center - strokeWidth - 2; // Subtract 2 to account for the glow effect
  const c = 2 * r * Math.PI;

  const totalValue = portions.reduce((sum, portion) => sum + portion.value, 0);

  return (
    <div className={cn(`relative ${sizeClass}`, className)}>
      <ProgressBar aria-label="Progress" value={totalValue}>
        {() => {
          let accumulatedPercentage = 0;
          const gapSize = 3; // 8px gap between portions
          const gapAngle = (gapSize / (2 * Math.PI * r)) * 360; // Convert gap size to angle
          return (
            <svg
              id={`progress-bar-radial-${randomId}`}
              width="100%"
              height="100%"
              viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
              fill="none"
              strokeWidth={strokeWidth}
            >
              {portions.map((portion, index) => {
                const portionPercentage = (portion.value / totalValue) * 100;
                const startPercentage = accumulatedPercentage;
                accumulatedPercentage += portionPercentage;

                const single = portions.length === 1;
                // For multiple portions, keep the existing gap logic
                const arcAngle = (portionPercentage / 100) * 360 - gapAngle;
                const arcLength = (arcAngle / 360) * c;
                const gapOffset = (gapAngle / 360) * c;

                return (
                  <circle
                    key={index}
                    cx={center}
                    cy={center}
                    r={r}
                    stroke={portion.color}
                    strokeLinecap="round"
                    strokeDasharray={
                      single ? `` : `${arcLength} ${c - arcLength}`
                    }
                    strokeDashoffset={
                      single
                        ? `0`
                        : `${(-startPercentage / 100) * c - gapOffset / 2}`
                    }
                    transform={single ? '' : `rotate(-90 ${center} ${center})`}
                  />
                );
              })}
            </svg>
          );
        }}
      </ProgressBar>

      {children && (
        <div className="absolute inset-0 flex items-center justify-center">
          {children}
        </div>
      )}
    </div>
  );
};
