import type { AriaLabelingProps, DOMProps } from '@react-types/shared';
import { type ForwardedRef, forwardRef } from 'react';
import { ProgressBar } from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';

export type SpinnerProps = {
  /**
   * The fill color of the spinner.
   * @default 'default'
   */
  fill?: 'accent' | 'default' | 'secondary' | 'subtle';
  /**
   * The size of the spinner.
   * @default 'md'
   */
  size?: 'sm' | 'md' | 'lg' | 'xl';
} & AriaLabelingProps &
  DOMProps &
  StyleProps;

export const Spinner = forwardRef(function Spinner(
  props: SpinnerProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    className,
    fill = 'default',
    size = 'md',
    style,
    ...otherProps
  } = props;

  return (
    <ProgressBar
      aria-label="Loading..."
      ref={ref}
      isIndeterminate
      className={cn(
        {
          'size-4': size === 'sm',
          'size-5': size === 'md',
          'size-6': size === 'lg',
          'size-10': size === 'xl',
        },
        {
          'text-accent': fill === 'accent',
          'text-default': fill === 'default',
          'text-secondary': fill === 'secondary',
          'text-subtle': fill === 'subtle',
        },
        className
      )}
      style={style}
      {...otherProps}
    >
      <svg
        aria-hidden
        fill="currentColor"
        focusable="false"
        role="img"
        viewBox="0 0 24 24"
        className="animate-spin"
      >
        <path d="M11.997 21.5c-1.3 0-2.528-.25-3.683-.747a9.63 9.63 0 0 1-3.026-2.041 9.628 9.628 0 0 1-2.04-3.026 9.196 9.196 0 0 1-.748-3.683c0-1.316.25-2.55.749-3.7a9.663 9.663 0 0 1 2.04-3.015A9.628 9.628 0 0 1 8.314 3.25 9.202 9.202 0 0 1 12 2.5c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.391-.216.535A.726.726 0 0 1 12 4c-2.217 0-4.104.78-5.663 2.337C4.78 7.896 4 9.783 4 12s.78 4.104 2.337 5.663C7.896 19.22 9.783 20 12 20s4.104-.78 5.663-2.337C19.22 16.104 20 14.217 20 12c0-.213.072-.391.216-.535a.727.727 0 0 1 .534-.215c.213 0 .391.072.535.216A.726.726 0 0 1 21.5 12c0 1.3-.25 2.528-.75 3.685a9.627 9.627 0 0 1-2.038 3.027 9.661 9.661 0 0 1-3.016 2.039c-1.15.5-2.383.749-3.7.749Z" />
      </svg>
    </ProgressBar>
  );
});
