import { TotalPatronsProgress } from './TotalPatronsProgress';

export const PatronClaimComponent = ({
  rightChildren,
  bottomChildren,
  totalPatrons,
  claimedPatrons,
  claimablePatrons,
  lockedPatrons,
  inPlatform,
}: {
  rightChildren?: React.ReactNode;
  bottomChildren?: React.ReactNode;
  totalPatrons: number;
  claimedPatrons: number;
  claimablePatrons: number;
  lockedPatrons: number;
  inPlatform?: boolean;
}) => {
  return (
    <div className="bg-surfaceOne flex flex-col items-center gap-y-16 rounded-2xl p-6 md:p-8">
      <div className="flex w-full flex-col items-center justify-center gap-x-10 sm:flex-row">
        <TotalPatronsProgress
          totalPatrons={totalPatrons}
          claimedPatrons={claimedPatrons}
          claimablePatrons={claimablePatrons}
          lockedPatrons={lockedPatrons}
          inPlatform={inPlatform}
        />
        {rightChildren}
      </div>
      {bottomChildren}
    </div>
  );
};
