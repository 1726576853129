import { filterDOMProps } from '@react-aria/utils';
import { type DOMProps } from '@react-types/shared';
import { type ForwardedRef, forwardRef, useMemo } from 'react';

import { type StyleProps } from '../../utils';
import { type SkeletonTextProps, SkeletonText } from './SkeletonText';

export type SkeletonParagraphProps = {
  /**
   * The number of lines to display.
   * @default 3
   */
  lines?: number;
} & Pick<SkeletonTextProps, 'size'> &
  DOMProps &
  StyleProps;

/** A convenience component for rendering `SkeletonText` over multiple lines. */
export const SkeletonParagraph = forwardRef(
  (props: SkeletonParagraphProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, lines = 3, size, style, ...otherProps } = props;
    const elements = useMemo(() => {
      return Array.from({ length: lines }).map((_, index) => {
        const isLastLine = lines > 1 && index + 1 === lines;
        return (
          <SkeletonText
            key={index}
            size={size}
            width={isLastLine ? [0.2, 0.4] : [0.8, 1]}
          />
        );
      });
    }, [lines, size]);

    return (
      <div
        role="presentation"
        ref={ref}
        className={className}
        style={style}
        {...filterDOMProps(otherProps)}
      >
        {elements}
      </div>
    );
  }
);
