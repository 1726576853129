import { ChainAsset } from '@infinex/asset-config';
import { AssetType } from '@infinex/asset-config';
import { erc20Abi, erc721Abi, getContract } from 'viem';

import { getEthNftsByCollection } from '../getNfts/getEvmNfts';
import { EvmClient, EvmNftMetadata } from '../types';
import {
  AccountAssetBalance,
  AssetBalance,
  EvmTokenBalance,
  NftMetadata,
} from '../types/balance';

/**
 * Gets a single token balance for a single account
 */
export async function getEvmTokenBalance<TClient extends EvmClient>({
  client,
  tokenContractAddress,
  accountAddress,
  type,
}: {
  client: TClient;
  tokenContractAddress: `0x${string}`;
  accountAddress: `0x${string}`;
  type: AssetType;
}): Promise<EvmTokenBalance> {
  try {
    const tokenContract = getContract({
      address: tokenContractAddress,
      abi: type === 'token' ? erc20Abi : erc721Abi,
      client,
    });

    const [balance, nfts] = await Promise.all([
      tokenContract.read.balanceOf([accountAddress]),
      type === 'nft'
        ? getEthNftsByCollection({
            client,
            accountAddress,
            nftContractAddresses: [tokenContractAddress],
          })
        : null,
    ]);

    return {
      balance,
      nftData: nfts?.[tokenContractAddress.toLowerCase()]?.map(mapToSubBalance),
    };
  } catch (e) {
    console.error(e);
    return { balance: 0n, failed: true };
  }
}

/**
 * Gets many token balances for a single account
 */
export async function getEvmAssetBalances<TClient extends EvmClient>({
  client,
  evmChainAssets,
  accountAddress,
}: {
  client: TClient;
  accountAddress: `0x${string}`;
  evmChainAssets: ChainAsset[];
}): Promise<AssetBalance[]> {
  const [balances, nftBalances] = await Promise.all([
    client.multicall({
      contracts: evmChainAssets.map((asset) => ({
        address: asset.address as `0x${string}`,
        abi: asset.type === 'token' ? erc20Abi : erc721Abi,
        functionName: 'balanceOf',
        args: [accountAddress],
      })),
    }),
    getEthNftsByCollection({
      client,
      accountAddress,
      nftContractAddresses: evmChainAssets
        .filter((a) => a.type === 'nft' && a.chain === 'ethereum') // Only Ethereum NFTs
        .map((a) => a.address as `0x${string}`),
    }),
  ]);

  // This will be caught outside
  if (!balances || balances.length !== evmChainAssets.length) {
    throw new Error('Mismatched balances');
  }

  // The results have no information to identify them, so we map them via index
  const assetBalances = balances.map((result, index) => {
    const address = evmChainAssets[index].address.toString();
    const ca = evmChainAssets[index];
    return {
      ...ca,
      type: ca.type,
      address,
      balance: result.status === 'success' ? (result.result as bigint) : 0n,
      nftData:
        ca.type === 'nft'
          ? nftBalances[address.toLowerCase()]?.map(mapToSubBalance)
          : undefined,
      failed: result.status !== 'success',
    };
  });

  return assetBalances as AssetBalance[];
}

function mapToSubBalance(nft: EvmNftMetadata): NftMetadata {
  return {
    identifier: nft.collectionTokenId,
    name: nft.name,
    imageUrl: nft.imageUrl,
  };
}

/**
 * Gets the balance of one token for many accounts
 */
export async function getEvmAssetBalancesForManyAccounts<
  TClient extends EvmClient,
>({
  client,
  evmChainAsset,
  accountAddresses,
  blockNumber,
}: {
  client: TClient;
  accountAddresses: `0x${string}`[];
  evmChainAsset: ChainAsset;
  blockNumber?: bigint;
}): Promise<AccountAssetBalance[]> {
  const results = await client.multicall({
    contracts: accountAddresses.map((accountAddress) => {
      return {
        address: evmChainAsset.address as `0x${string}`,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [accountAddress],
      };
    }),
    blockNumber,
  });

  // The results have no information to identify them, so we map them via index
  return results.map((result, index) => ({
    ...evmChainAsset,
    accountAddress: accountAddresses[index],
    assetAddress: evmChainAsset.address.toString() as `0x${string}`,
    balance: result.status === 'success' ? (result.result as bigint) : 0n,
    failed: result.status !== 'success',
  }));
}
