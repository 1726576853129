import { fromAbsolute, getLocalTimeZone } from '@internationalized/date';
import {
  type ForwardedRef,
  forwardRef,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDateFormatter, useNumberFormatter } from 'react-aria';
import {
  type TooltipProps,
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import type { CurveType } from 'recharts/types/shape/Curve';

import tokens from '../../styles/tokens';
import { CONTROL_HEIGHT, DOT_RADIUS, LINE_WIDTH } from './constants';
import { PeriodControl } from './PeriodControl';
import {
  type PeriodKey,
  DEFAULT_PERIODS,
  getDateFormatterOptions,
  getRangeConstraints,
} from './periods';

type UtcItem = { value: number; date: string };
type EpochItem = { value: number; time: number };

export type ValueOverTimeChartProps = {
  /**
   * Influence chart accents, like the line stroke, area gradient, etc.
   * @default 'brand'
   */
  accentColor?: 'brand' | 'trend';
  /**
   * Which periods to allow. Used to define how granularly users may slice the
   * time data, or to remove irrelevant periods depending on the size and
   * duration of your dataset.
   */
  allowedPeriods?: PeriodKey[];
  /**
   * The data to display in the chart. The `date` value expects a UTC string
   * e.g. "2024-09-03T02:27:53Z"
   */
  data: UtcItem[];
  /**
   * The height of the chart element, not including the range contol.
   * @default 320
   */
  height?: number;
  /**
   * Handler that is called when the user changes the time period.
   */
  onPeriodChange: (payload: ReturnType<typeof getRangeConstraints>) => void;
  /** The current time period. */
  period: PeriodKey;
};

export const ValueOverTimeChart = (props: ValueOverTimeChartProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [isTooltipActive, setTooltipActive] = useState(false);
  const [viewbox, setViewbox] = useState({ width: 0, height: 0 });
  const [xCoordinate, setXCoordinate] = useState(0);

  const dateFormatter = useDateFormatter(getDateFormatterOptions(props.period));
  const numberFormatter = useNumberFormatter({
    compactDisplay: 'short',
    currency: 'AUD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
    notation: 'compact',
    style: 'currency',
  });

  // convert date string so the chart doesn't treat it like a category
  const timeData = useMemo(() => {
    return props.data.map(utcToEpochItem);
  }, [props.data]);
  // distribute ticks as best as possible for the given period
  const ticks = useMemo(() => {
    const { start, end, tickInterval } = getRangeConstraints(props.period);
    const uniqueTicks: Set<number> = new Set();
    const fiveMinutes = 5 * 60 * 1000;

    let endTime = new Date(end).getTime();
    let startTime = new Date(start).getTime();

    // normalized to nearest 5 min for granular periods
    if (props.period === '1h' || props.period === '1d') {
      endTime = Math.round(endTime / fiveMinutes) * fiveMinutes;
      startTime = Math.round(startTime / fiveMinutes) * fiveMinutes;
    }

    let currentTick = startTime;

    while (currentTick <= endTime) {
      uniqueTicks.add(currentTick);
      currentTick += tickInterval * 1000;
    }

    return Array.from(uniqueTicks);
  }, [props.period]);
  const accentColor = useMemo(() => {
    if (!props.accentColor || props.accentColor === 'brand') {
      return tokens.fill.accent;
    }

    const length = props.data.length;
    const first = props.data[0];
    const last = props.data[length - 1];

    return first.value <= last.value ? tokens.fill.bull : tokens.fill.bear;
  }, [props.accentColor, props.data]);
  const allowedPeriods = useMemo(() => {
    return new Set(props.allowedPeriods ?? DEFAULT_PERIODS);
  }, [props.allowedPeriods]);

  return (
    <div onTouchEnd={() => setTooltipActive(false)}>
      <PeriodControl
        allowedKeys={allowedPeriods}
        isHidden={isTooltipActive}
        onChange={(key) => {
          props.onPeriodChange(getRangeConstraints(key));
        }}
        value={props.period}
      />
      <ResponsiveContainer
        onResize={(width, height) => setViewbox({ width, height })}
        height={props.height ?? 320}
        width="100%"
      >
        <AreaChart
          data={timeData}
          onMouseMove={(e) => {
            setTooltipActive(!!e.isTooltipActive);
            setXCoordinate((prev) => {
              const tooltip = tooltipRef.current;
              if (!e.activeCoordinate || !tooltip) {
                return prev;
              }

              const limit = tooltip.offsetWidth / 2;
              return clamp(e.activeCoordinate.x, limit, viewbox.width - limit);
            });
          }}
          onMouseLeave={() => setTooltipActive(false)}
          // stop the active dot from being clipped
          margin={{
            top: DOT_RADIUS,
            left: DOT_RADIUS,
            right: DOT_RADIUS,
            bottom: 0,
          }}
        >
          <XAxis
            allowDecimals={false}
            dataKey="time"
            domain={['dataMin', 'dataMax']}
            name="Time"
            scale="time"
            ticks={ticks}
            type="number"
            // ↑ behaviour ↓ appearance
            axisLine={{ stroke: tokens.borderColor.interactive }}
            height={20} // magic number: roughly, text + spacing
            minTickGap={32} // magic number: looks about right
            padding={{ left: DOT_RADIUS, right: DOT_RADIUS }}
            tickLine={{
              stroke: tokens.borderColor.interactive,
              transform: 'translate(0, 0.5)',
            }}
            tickMargin={4}
            tickSize={4}
            tick={{
              fill: tokens.textColor.secondary,
              // sync with "body-xs-normal"
              fontSize: '0.75rem',
              letterSpacing: '0.03rem',
            }}
            tickFormatter={(value) => {
              const zonedDatetime = fromAbsolute(value, getLocalTimeZone());
              return dateFormatter.format(zonedDatetime.toDate());
            }}
          />
          <YAxis
            hide
            dataKey="value"
            interval="preserveEnd"
            name="Value"
            type="number"
            // ↑ behaviour ↓ appearance
            width={32}
            orientation="right"
            axisLine={{ stroke: tokens.borderColor.interactive }}
            tickLine={{ stroke: tokens.borderColor.interactive }}
            tick={{
              fill: tokens.textColor.secondary,
              // sync with "body-xs-normal"
              fontSize: '0.75rem',
              letterSpacing: '0.03rem',
            }}
            tickFormatter={(value) => {
              return numberFormatter.format(value / 100);
            }}
          />

          <Tooltip
            active={isTooltipActive}
            cursor={{
              stroke: tokens.borderColor.interactive,
              strokeDasharray: 2,
            }}
            // isAnimationActive={false}
            animationDuration={150}
            position={{ x: xCoordinate, y: CONTROL_HEIGHT * -1 }}
            allowEscapeViewBox={{ x: true, y: true }}
            wrapperStyle={{ color: accentColor }}
            content={<DateValueTooltip ref={tooltipRef} />}
          />

          <Area
            animationDuration={150}
            type={getCurveType(props.period)}
            dataKey="value"
            stroke={accentColor}
            strokeWidth={LINE_WIDTH}
            strokeLinecap="round"
            fill="url(#gradient-fill)"
            fillOpacity={0.2}
            dot={false}
            activeDot={
              isTooltipActive
                ? {
                    stroke: tokens.backgroundColor.canvas,
                    strokeWidth: LINE_WIDTH,
                    r: DOT_RADIUS,
                  }
                : false
            }
          />
          <defs>
            <linearGradient id="gradient-fill" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={accentColor} stopOpacity={0.8} />
              <stop offset="75%" stopColor={accentColor} stopOpacity={0} />
            </linearGradient>
          </defs>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

// Styled components
// ------------------------------

// consider renderer for safer/simpler types
// see: https://recharts.org/en-US/api/Tooltip#content
const DateValueTooltip = forwardRef(function CustomTooltip(
  props: unknown,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { active, payload } = props as TooltipProps<number, 'time'>;
  const numberFormatter = useNumberFormatter({
    currency: 'AUD',
    currencyDisplay: 'narrowSymbol',
    style: 'currency',
  });
  const dateFormatter = useDateFormatter({
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
  });

  if (!active || !payload?.length) {
    return null;
  }

  const item = payload[0].payload as EpochItem;
  return (
    // separate transform style from the animated element to avoid undesirable
    // "slide in" behaviour
    <div style={{ transform: 'translate(-50%, 0)' }}>
      <div
        ref={ref}
        className="animate-in fade-in bg-canvas body-base-normal pointer-events-none relative flex items-center gap-2 whitespace-nowrap rounded text-center tabular-nums duration-300 ease-out"
        style={{
          height: CONTROL_HEIGHT,
          paddingInline: DOT_RADIUS,
        }}
      >
        <span className="font-medium">
          {numberFormatter.format(item.value / 100)}
        </span>
        <span className="text-secondary">
          {dateFormatter.format(
            fromAbsolute(item.time, getLocalTimeZone()).toDate()
          )}
        </span>
      </div>
    </div>
  );
});

// Utils
// ------------------------------

function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(value, max));
}

function getCurveType(key: PeriodKey): CurveType {
  switch (key) {
    case '1h':
      return 'step';
    case '1d':
    case '1w':
      return 'monotone';
    default:
      return 'linear';
  }
}

function utcToEpochItem(item: UtcItem): EpochItem {
  return {
    time: new Date(item.date).getTime(),
    value: item.value,
  };
}
