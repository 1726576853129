import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';
import { cn } from '../../utils';

const dividerVariants = cva('', {
  variants: {
    prominence: {
      default: 'bg-divider',
      subtle: 'bg-dividerSubtle',
      onLight: 'bg-dividerOnLight',
    },
    orientation: {
      horizontal: 'h-[0.0625rem] w-full',
      vertical: 'h-full w-[0.0625rem]',
    },
  },
  defaultVariants: {
    prominence: 'default',
  },
});

export interface DividerProps extends VariantProps<typeof dividerVariants> {
  orientation?: 'horizontal' | 'vertical';
  className?: string;
}

export const Divider = React.forwardRef<HTMLInputElement, DividerProps>(
  (
    { orientation = 'horizontal', prominence = 'default', className, ...props },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={cn(dividerVariants({ prominence, orientation, className }))}
        {...props}
      />
    );
  }
);

Divider.displayName = 'Divider';
