import { screenValues } from '../../styles/screens';

// Object.assign chosen to improve clarity of derived types; spread moves `base`
// property to the end of object and string union types.
export const breakpoints = Object.assign({ base: 0 }, screenValues);

export type Breakpoint = keyof typeof breakpoints;

export type BreakpointRange =
  | { above: Exclude<Breakpoint, '2xl'> }
  | { below: Exclude<Breakpoint, 'base'> };

export type ResponsiveProp<T> = Partial<Record<Breakpoint, T>>;
