import { cva } from 'class-variance-authority';
import {
  type ForwardedRef,
  type ReactNode,
  createContext,
  forwardRef,
  useContext,
} from 'react';
import {
  type InputProps as RACInputProps,
  type GroupProps,
  type LabelProps as RACLabelProps,
  type FieldErrorProps as FieldErrorProps,
  type TextFieldRenderProps,
  FieldError as FieldError,
  FieldErrorContext,
  Group,
  Input as RACInput,
  Label,
  Provider,
} from 'react-aria-components';

import { IconContext } from '../../icons';
import { type LiteralVariantProps, type StyleProps, cn } from '../../utils';
import { Text, TextContext } from '../content';

// Field context
// -----------------------------------------------------------------------------

type FieldContextValue = {
  size: InputVariant['size'];
} & TextFieldRenderProps;

export type FieldProviderProps = { children: ReactNode } & FieldContextValue;

const FieldContext = createContext<FieldContextValue>({
  isDisabled: false,
  isInvalid: false,
  isReadOnly: false,
  isRequired: false,
  size: 'medium',
});

/**
 * @private Provides common context for the various field components, to limit
 * API surface area and avoid prop drilling.
 */
export function FieldProvider(props: FieldProviderProps) {
  const { isDisabled, isInvalid, isReadOnly, isRequired, size } = props;

  return (
    <FieldContext.Provider
      value={{ isDisabled, isInvalid, isReadOnly, isRequired, size }}
    >
      {props.children}
    </FieldContext.Provider>
  );
}

// Field label
// -----------------------------------------------------------------------------

export type FieldLabelProps = RACLabelProps & { extension?: string };

/**
 * @private A thin wrapper around react-aria-components' `Label` component, for
 * specific styling and behaviour.
 */
export const FieldLabel = forwardRef(function FieldLabel(
  props: FieldLabelProps,
  ref: ForwardedRef<HTMLLabelElement>
) {
  const { children, className, extension, ...otherProps } = props;
  return (
    <Label
      ref={ref}
      className={cn('text-secondary body-sm-medium leading-none', className)}
      {...otherProps}
    >
      {children}
      {extension ? <span className="font-light"> {extension}</span> : null}
    </Label>
  );
});

// Help text
// -----------------------------------------------------------------------------

export type HelpTextProps = {
  /**
   * The description explains requirements or adds supplementary context for how
   * to successfully interact with a component.
   */
  description?: ReactNode;
} & Pick<FieldErrorProps, 'children'>;

/**
 * @private Help text provides either an informative description or an error
 * message that gives more context about what a user needs to input.
 */
export const HelpText = forwardRef(function HelpText(
  props: HelpTextProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const errorCtx = useContext(FieldErrorContext);

  if (!errorCtx.isInvalid && props.description) {
    return (
      <Text slot="description" className={cn('body-xs-normal text-subtle')}>
        {props.description}
      </Text>
    );
  }

  return (
    <FieldError ref={ref} className={cn('text-critical body-xs-normal')}>
      {props.children}
    </FieldError>
  );
});

// Input
// -----------------------------------------------------------------------------

const inputVariants = cva(
  'w-full bg-none outline-none placeholder:text-subtle text-default caret-interactiveActive disabled:text-disabled',
  {
    variants: {
      // fix for mobile safari zoom behaviour:
      // - "touch" prefix targets `hover: none + pointer: coarse` by media query
      // - increase font to 16px (min allowed)
      size: {
        small: 'body-sm-normal h-8 px-3 touch:body-base-normal',
        medium: 'body-sm-normal h-10 px-3 touch:body-base-normal',
        large: 'body-base-normal h-12 px-4',
      },
    },
  }
);

type InputVariant = LiteralVariantProps<typeof inputVariants>;
export type InputProps = Omit<RACInputProps, 'className' | 'style'> &
  StyleProps;

/**
 * @private A thin wrapper around react-aria-components' `Input` component, for
 * specific styling and behaviour.
 */
export const Input = forwardRef(function Input(
  props: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { className, ...otherProps } = props;
  const { size } = useContext(FieldContext);
  return (
    <RACInput
      {...otherProps}
      ref={ref}
      className={cn(inputVariants({ size }), className)}
    />
  );
});

// Input group
// -----------------------------------------------------------------------------

export type InputGroupProps = Omit<GroupProps, 'className' | 'style'> &
  StyleProps;

/**
 * @private A container for the input element, adornments and related controls.
 * It provides consistent layout and styling.
 */
export const InputGroup = forwardRef(function InputGroup(
  props: InputGroupProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { children, className } = props;

  const fieldCtx = useContext(FieldContext);
  const slotClasses = getInputGroupClasses(fieldCtx);

  return (
    <Provider
      values={[
        // FIXME: work out how to get mixed contexts + slots to behave with TS
        // @ts-expect-error: we're combining internal and RAC contexts
        [TextContext, { className: slotClasses.text, slot: null }],
        [IconContext, { className: slotClasses.icon }],
      ]}
    >
      <Group
        isDisabled={fieldCtx.isDisabled}
        isInvalid={fieldCtx.isInvalid}
        ref={ref}
        // Forward focus to the input element when press on a non-interactive
        // child. Mimic browser behaviour:
        // - via mouse, focus immediately
        // - via touch, make sure it's not a scroll attempt
        onPointerDown={(e) => {
          if (
            e.pointerType === 'mouse' &&
            !(e.target as Element).closest('button,input,textarea')
          ) {
            e.preventDefault();
            e.currentTarget.querySelector('input')?.focus();
          }
        }}
        onPointerUp={(e) => {
          if (
            e.pointerType !== 'mouse' &&
            !(e.target as Element).closest('button,input,textarea')
          ) {
            e.preventDefault();
            e.currentTarget.querySelector('input')?.focus();
          }
        }}
        className={cn(
          'relative z-0 flex w-full cursor-text',
          'bg-shark4 rounded border-none outline-none transition',
          'hover:bg-shark5',
          'focus-within:bg-shark4 focus-within:ring-1 focus-within:ring-inset focus-within:ring-opacity-100',
          'disabled:bg-shark3 disabled:cursor-default',
          className
        )}
      >
        {children}
      </Group>
    </Provider>
  );
});

function getInputGroupClasses(props: FieldContextValue) {
  const common = [
    'text-secondary select-none self-center shrink-0',
    'last:me-3 first:ms-3',
    { 'text-disabled': props.isDisabled },
  ];
  const icon = cn(common, {
    'size-5': props.size === 'small' || props.size === 'medium',
    'size-6': props.size === 'large',
  });
  const text = cn(common, {
    'body-sm-normal': props.size === 'small' || props.size === 'medium',
    'body-base-normal': props.size === 'large',
  });

  return { icon, text };
}
