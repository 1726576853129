import { useUserStore } from 'stores';

export default function usePatronData() {
  const vestingSchedule = useUserStore((state) => state.vestingSchedule);

  // Data not fully loaded
  if (!vestingSchedule) return null;

  // Get the schedule data for liquid (1), linear (4), and locked (5)
  const scheduleData = ([1, 4, 5] as const).map(
    (index) => vestingSchedule[index]
  );

  // Data not fully loaded
  if (
    !scheduleData.every((data) =>
      Object.values(data).some((value) => value !== null)
    )
  ) {
    return null;
  }

  const [liquid, linear, locked] = scheduleData;

  const totalPatrons = scheduleData.reduce((sum, { total }) => sum + total, 0);
  const claimablePatrons =
    scheduleData.reduce((sum, { claimable }) => sum + claimable, 0) +
    liquid.locked;
  const lockedPatrons =
    scheduleData.reduce((sum, { locked }) => sum + locked, 0) - liquid.locked;
  const claimedPatrons = totalPatrons - lockedPatrons - claimablePatrons;

  const vestedLinear = linear.total - linear.locked;
  const vestedLocked = locked.total - locked.locked;

  return {
    totalPatrons,
    claimablePatrons,
    lockedPatrons,
    claimedPatrons,
    vestedLinear,
    vestedLocked,
    liquid,
    linear,
    locked,
  };
}
