import { formatNumber } from '../../../../utils/utils';
import { VestingItem } from './index';
import { LockClockIcon, ProgressCompleteIcon } from '@infinex/ui/icons';
import { Button, Divider } from '@infinex/ui/components';
import { cn } from '@infinex/ui/utils';
import { useClaim } from 'hooks';
import { useState } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export default function ClaimPatronTableMobile({
  vestingItems,
}: {
  vestingItems: VestingItem[];
}) {
  return (
    <div className="block sm:hidden">
      <div className="flex flex-col gap-y-4">
        {vestingItems.map((vestingItem) => (
          <VestingItemCard key={vestingItem.id} vestingItem={vestingItem} />
        ))}
      </div>
    </div>
  );
}

function VestingItemCard({ vestingItem }: { vestingItem: VestingItem }) {
  const { claimPatrons } = useClaim();
  const [loadingTier, setLoadingTier] = useState<number | null>(null);

  const isClaimingEnabled = useFeatureFlagEnabled('claim-patron-dapp') ?? false;

  const handleClaim = async (tierNumber: number) => {
    await claimPatrons(tierNumber, setLoadingTier);
  };

  const isAllClaimed =
    vestingItem.claimed === vestingItem.total && vestingItem.claimed > 0;
  const isLocked = vestingItem.tier === 'locked';

  return (
    <div className="flex flex-col gap-y-8 rounded-xl border p-6">
      <div className="flex flex-col items-center gap-y-6">
        <h1 className="title-lg-medium text-center">{vestingItem.tier}</h1>
        {isAllClaimed && (
          <StatusBadge
            icon={
              <ProgressCompleteIcon className="text-positiveSubtle size-4" />
            }
            text="All patrons claimed"
          />
        )}
        {isLocked && (
          <StatusBadge
            icon={<LockClockIcon className="text-ruby11 size-4" />}
            text="Locked until oct '25"
          />
        )}
      </div>
      <div>
        {[
          { label: 'Total', value: vestingItem.total },
          { label: 'Claimed', value: vestingItem.claimed },
          {
            label: 'Available to Claim',
            value: vestingItem.available_to_claim,
          },
        ].map(({ label, value }, index, array) => (
          <CardBodyItem
            key={label}
            label={label}
            value={value}
            isLast={index === array.length - 1}
          />
        ))}
      </div>
      {vestingItem.available_to_claim > 0 && (
        <div>
          <Button
            size="medium"
            prominence="high"
            className="w-full"
            isPending={loadingTier === vestingItem.tier_number}
            onPress={() => handleClaim(vestingItem.tier_number)}
            isDisabled={!isClaimingEnabled}
          >
            Claim
          </Button>
        </div>
      )}
    </div>
  );
}

function StatusBadge({ icon, text }: { icon: React.ReactNode; text: string }) {
  return (
    <div className="flex gap-x-2 rounded-full border px-3 py-2">
      {icon}
      <p className="title-xs-medium text-subtle">{text}</p>
    </div>
  );
}

function CardBodyItem({
  label,
  value,
  isLast,
}: {
  label: string;
  value: number;
  isLast: boolean;
}) {
  const isClaimable = label === 'Available to Claim' && value > 0;
  const hasClaimed = label === 'Claimed' && value > 0;

  return (
    <div>
      <div className="flex justify-between">
        <p className="title-xs-medium text-subtle">{label}</p>
        <p
          className={cn(
            'title-sm-medium text-slate11',
            isClaimable && 'text-[#70FF93]',
            hasClaimed && 'text-accentDark'
          )}
        >
          {formatNumber(value)}
        </p>
      </div>
      {!isLast && <Divider className="my-4" />}
    </div>
  );
}
