import React, {
  type PropsWithChildren,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';

import { type Breakpoint, breakpoints } from './breakpoints';

const BreakpointContext = React.createContext<Breakpoint[]>(['base']);

export function BreakpointProvider(props: PropsWithChildren) {
  const matchedBreakpoints = useMatchedBreakpoints();
  return (
    <BreakpointContext.Provider value={matchedBreakpoints}>
      {props.children}
    </BreakpointContext.Provider>
  );
}

/** @private Exported for internal use. */
export function useBreakpointContext() {
  return useContext(BreakpointContext);
}

// Utils
// ----------------------------------------------------------------------------

// avoid unnecessary query for 'base', it will always match
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const breakpointsWithoutBase = (({ base, ...rest }) => rest)(breakpoints);

// entries from largest to smallest
const breakpointEntries = (
  Object.entries(breakpointsWithoutBase) as [Breakpoint, number][]
).sort(([, valueA], [, valueB]) => valueB - valueA);

const breakpointQueries = breakpointEntries.map(
  ([, value]) => `(min-width: ${value}px)`
);

function useMatchedBreakpoints(): Breakpoint[] {
  const supportsMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia === 'function';
  const getMatchedBreakpoints = () => {
    const matched: Breakpoint[] = [];
    for (const i in breakpointQueries) {
      const query = breakpointQueries[i];
      if (window.matchMedia(query).matches) {
        matched.push(breakpointEntries[i][0]);
      }
    }
    matched.push('base');
    return matched;
  };
  const [breakpoint, setBreakpoint] = useState(() => {
    return supportsMatchMedia ? getMatchedBreakpoints() : ['base' as const];
  });

  useLayoutEffect(() => {
    if (!supportsMatchMedia) {
      return;
    }

    const onResize = () => {
      const matched = getMatchedBreakpoints();
      setBreakpoint((prev) => {
        if (
          prev.length !== matched.length ||
          prev.some((breakpoint, idx) => breakpoint !== matched[idx])
        ) {
          return matched;
        }

        return prev;
      });
    };
    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [supportsMatchMedia]);

  return breakpoint;
}
