import { mergeProps, useObjectRef } from '@react-aria/utils';
import { cva } from 'class-variance-authority';
import { type ForwardedRef, type ReactNode, forwardRef } from 'react';
import { useButton, useFocusRing, useHover } from 'react-aria';

import { ArrowOutwardIcon } from '../../icons';
import { cn } from '../../utils';
import { Link } from './Link';
import type { AnchorDOMProps, CommonLinkProps } from './types';

type TextLinkAnchorProps = {
  /** Indicate that the link will open in a new tab. */
  isExternal?: boolean;
} & AnchorDOMProps &
  CommonTextLinkProps;

type CommonTextLinkProps = {
  /** The content of the element. */
  children: ReactNode;
  /**
   * The visual prominence of the link.
   * @default 'medium'
   */
  prominence?: 'high' | 'medium' | 'low';
} & CommonLinkProps;

type TextLinkButtonProps = CommonTextLinkProps;

export type TextLinkProps = TextLinkAnchorProps | TextLinkButtonProps;

/**
 * Text links take users to another place in the application, and usually appear
 * within or directly following a sentence. Styled to resemble a hyperlink.
 */
export const TextLink = forwardRef(function TextLink(
  props: TextLinkProps,
  ref: ForwardedRef<HTMLAnchorElement | HTMLSpanElement>
) {
  const className = cn(
    variants({ prominence: props.prominence }),
    props.className
  );

  if (isAnchorProps(props)) {
    return (
      <Link
        {...props}
        className={className}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        target={props.isExternal ? '_blank' : props.target}
      >
        {props.children}
        {props.isExternal && (
          <span className="inline text-nowrap">
            {wordJoiner}
            <ArrowOutwardIcon
              aria-label=", opens in new tab"
              // match the size of the text, but don't let it shrink smaller than
              // 1rem/16px to avoid weird SVG rendering artifacts
              className="inline size-[1em] min-h-4 min-w-4"
            />
          </span>
        )}
      </Link>
    );
  }

  return (
    <TextLinkButton
      {...props}
      ref={ref as ForwardedRef<HTMLSpanElement>}
      className={className}
    />
  );
});

// Word joiner character: a zero-width non-breaking character to prevent the
// icon wrapping onto its own line.
const wordJoiner = '\u2060';

function isAnchorProps(props: TextLinkProps): props is TextLinkAnchorProps {
  return 'href' in props && props.href != null;
}

// Would **much** prefer to use the `Button` component from
// "react-aria-components". All the shenanigans below are to fix a
// presentational limitation of the `<button/>` element, which refuses to
// display as `inline[-*]` no matter what CSS you throw at it.
const TextLinkButton = forwardRef(function TextLinkButton(
  props: TextLinkButtonProps,
  forwardedRef: ForwardedRef<HTMLSpanElement>
) {
  const { children, className, style, ...otherProps } = props;

  const domRef = useObjectRef(forwardedRef);
  const { buttonProps, isPressed } = useButton(
    { elementType: 'span', ...otherProps },
    domRef
  );
  const { focusProps, isFocused, isFocusVisible } = useFocusRing(props);
  const { hoverProps, isHovered } = useHover(props);

  return (
    <span
      {...mergeProps(buttonProps, focusProps, hoverProps)}
      ref={domRef}
      className={className}
      style={style}
      data-rac
      data-disabled={props.isDisabled || undefined}
      data-pressed={isPressed || undefined}
      data-hovered={isHovered || undefined}
      data-focused={isFocused || undefined}
      data-focus-visible={isFocusVisible || undefined}
    >
      {children}
    </span>
  );
});

const variants = cva(
  `
  break-word outline-none transition-all cursor-pointer min-w-0 underline underline-offset-2 decoration-solid [text-decoration-thickness:1px]
  aria-disabled:no-underline aria-disabled:cursor-auto
  `,
  {
    variants: {
      prominence: {
        high: `
        hover:decoration-transparent
        focus-visible:decoration-double
        `,
        medium: `
        decoration-[color-mix(in_srgb,currentColor_40%,transparent)]
        hover:decoration-[currentColor]
        focus-visible:decoration-[currentColor] focus-visible:decoration-double
      `,
        low: `
        decoration-transparent	
        hover:brightness-150
        focus-visible:brightness-150 focus-visible:decoration-[currentColor] focus-visible:decoration-double
      `,
      },
    },
    defaultVariants: {
      prominence: 'medium',
    },
  }
);
