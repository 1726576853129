import {
  ChainAsset,
  ChainAssetId,
  ChainKey,
  Environment,
  EvmChainKey,
  getActiveChainKeys,
  getSupportedChainAssets,
  SolanaChainKey,
} from '@infinex/asset-config';
import { Connection, PublicKey } from '@solana/web3.js';
import {
  type Address,
  Chain,
  createPublicClient,
  http,
  HttpTransport,
} from 'viem';
import {
  arbitrum,
  arbitrumSepolia,
  base,
  baseSepolia,
  blast,
  blastSepolia,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  sepolia,
} from 'viem/chains';

import { getBalances } from './getBalances';
import { getDecimals } from './getDecimals';
import { getTokenMetadata } from './getMetadata';
import { AssetBalance, CustomAssetLookup, EvmClient } from './types';

type ClientConfig = {
  env: Environment;
  chains?: {
    [Chain in ChainKey]: {
      rpcUrl?: Chain extends 'solana' ? string | Connection : string;
    };
  };
  pollingInterval?: number;
  chainAssetOverrides?: Record<ChainAssetId, Partial<ChainAsset>>;
};

// TODO: Better name, different spot?
export const getChainClustersFromEnv = (env: Environment) => {
  const isProdOrStaging = env === 'prod' || env === 'staging';
  return {
    arbitrum: isProdOrStaging ? arbitrum : arbitrumSepolia,
    base: isProdOrStaging ? base : baseSepolia,
    blast: isProdOrStaging ? blast : blastSepolia,
    ethereum: isProdOrStaging ? mainnet : sepolia,
    optimism: isProdOrStaging ? optimism : optimismSepolia,
    polygon: isProdOrStaging ? polygon : polygonAmoy,
    solana: isProdOrStaging
      ? 'https://api.mainnet-beta.solana.com'
      : 'https://api.devnet.solana.com',
  };
};

export function createClient(config: ClientConfig) {
  const chainClusters = getChainClustersFromEnv(config.env);
  const rpcClients: Record<EvmChainKey, EvmClient> &
    Record<SolanaChainKey, Connection> = {
    arbitrum: getRpcClient(config, 'arbitrum'),
    base: getRpcClient(config, 'base'),
    blast: getRpcClient(config, 'blast'),
    ethereum: getRpcClient(config, 'ethereum'),
    optimism: getRpcClient(config, 'optimism'),
    polygon: getRpcClient(config, 'polygon'),
    solana:
      // `instanceof Connection` may not always work if versions differ
      typeof config.chains?.solana.rpcUrl === 'object' &&
      'getBlock' in config.chains.solana.rpcUrl
        ? config.chains?.solana.rpcUrl
        : new Connection(
            config.chains?.solana.rpcUrl ?? chainClusters.solana,
            'confirmed'
          ),
  };

  // Override some assets for local and testing environments
  let assetOverrides = config.chainAssetOverrides;
  if (config.env === 'dev' || config.env === 'test') {
    assetOverrides =
      assetOverrides ?? ({} as Record<ChainAssetId, Partial<ChainAsset>>);
    assetOverrides[JUMBOS_DOG_BASE_USDT.chainAssetId] = JUMBOS_DOG_BASE_USDT;
  }

  const { supportedChainAssets, supportedChainAssetActions } =
    getSupportedChainAssets(config.env, assetOverrides);

  const activeChainKeys = getActiveChainKeys(config.env);

  return {
    supportedChainAssets,
    supportedChainAssetActions,
    async getDecimals({ chainAssets }: { chainAssets: ChainAsset[] }) {
      const decimalMap = await getDecimals({
        rpcClients,
        assets: chainAssets,
      });
      return decimalMap;
    },
    async getMetadata({
      assetLookups,
      heliusApiUrl,
    }: {
      assetLookups: CustomAssetLookup[];
      heliusApiUrl: string;
    }) {
      return await getTokenMetadata({
        rpcClients,
        heliusApiUrl,
        assetLookups,
      });
    },
    async getBalances({
      evmAccountAddress,
      solanaAccountAddress,
      chains = activeChainKeys,
    }: {
      evmAccountAddress?: Address;
      solanaAccountAddress?: string | PublicKey;
      chains?: ReadonlyArray<ChainKey>;
    }) {
      const balances = await getBalances({
        solanaAccountAddress,
        evmAccountAddress,
        rpcClients,
        assets: supportedChainAssets,
        chains,
      });

      return balances;
    },
    watchBalances({
      evmAccountAddress,
      solanaAccountAddress,
      onChange,
      onError,
      chains = activeChainKeys,
    }: {
      evmAccountAddress?: Address;
      solanaAccountAddress?: string | PublicKey;
      onChange: (
        balances: AssetBalance[],
        prevBalances: AssetBalance[]
      ) => void;
      chains?: ChainKey[];
      onError?: (error: Error) => void;
    }) {
      let prevBalances: AssetBalance[];

      getBalances({
        evmAccountAddress,
        solanaAccountAddress,
        rpcClients,
        assets: supportedChainAssets,
        chains,
      }).then((balances) => (prevBalances = balances), onError);

      const timer = setInterval(
        () =>
          getBalances({
            evmAccountAddress,
            solanaAccountAddress,
            rpcClients,
            assets: supportedChainAssets,
            chains,
          }).then((balances) => {
            if (
              prevBalances &&
              stringify(balances) !== stringify(prevBalances)
            ) {
              onChange(balances, prevBalances);
            }
            prevBalances = balances;
          }, onError),
        config.pollingInterval ?? 8000
      );

      return () => clearInterval(timer);
    },
  };
}

function stringify(obj: any) {
  return JSON.stringify(obj, (key, value) =>
    typeof value === 'bigint' ? value.toString() : value
  );
}

// TODO: fix this to have the correct amount of decimals as USDT
const JUMBOS_DOG_BASE_USDT: ChainAsset = {
  chainAssetId: 'base_usd_tether',
  assetId: 'usd_tether',
  chain: 'base',
  name: 'Tether',
  type: 'token',
  symbol: 'USDT',
  coingeckoId: 'tether',
  decimals: 18,
  address: '0x6f9fe4bD27c2547CEB49264ACF6914418AFC7047',
};

function getRpcClient(config: ClientConfig, chain: EvmChainKey): EvmClient {
  const chainClusters = getChainClustersFromEnv(config.env);
  // We explicitly provide types here to avoid slow inference time
  // @see `Client` type in `types/index.ts`
  return createPublicClient<HttpTransport, undefined, undefined, undefined>({
    batch: { multicall: true },
    chain: chainClusters[chain] as Chain,
    transport: http(config.chains?.[chain].rpcUrl),
  }) as unknown as EvmClient;
}
