import { ToastContentProps, toast as reactToastify } from 'react-toastify';
import { CloseIcon } from '../../icons';
import { cn } from '../../utils';

export interface ToastProps {
  label: string;
  content: string | JSX.Element;
  variant: 'default' | 'error' | 'onchain' | 'boost' | 'bonus';
  extension?: JSX.Element;
  duration?: number;
}

/**
 * `Toast` is a component that displays a toast notification with a label, content, and optional extension.
 * The toast can have different variants: 'default', 'error', or 'onchain'.
 *
 * @param {string} label - The label of the toast.
 * @param {string | JSX.Element} content - The content of the toast, shows under the label.
 * @param {JSX.Element} [extension] - An optional extension for the toast, this sits on the right side of the content.
 * @param {'default' | 'error' | 'onchain' | 'boost'} variant - The variant of the toast. Default shows no badge.
 * @param {number} [duration=5000] - The duration of the toast in milliseconds.
 *
 * @example
 * Toast({
 *   label: 'AUTHENTICATION METHOD ADDED',
 *   variant: 'onchain',
 *   content: (
 *     <>
 *       <EthereumIcon className="size-6" />
 *       <span>EVM NETWORKS</span>
 *     </>
 *   ),
 *   extension: (
 *     <BlockExplorerLink />
 *   ),
 * });
 */
export const Toast = ({
  label,
  content,
  extension,
  variant,
  duration = 5000,
}: ToastProps) => {
  const renderToast = ({ closeToast }: ToastContentProps) => (
    <div className="relative flex h-full items-center justify-between py-2">
      <div className="border-subtle w-full space-y-3 border-r px-4 py-2">
        {/* Label */}
        <div className="flex items-center justify-between gap-x-2">
          <div className="text-secondary title-xs-medium">{label}</div>

          {/* Variant badges */}
          {variant === 'onchain' && <VariantOnchainBadge />}
          {variant === 'boost' && <VariantBoostBadge />}
          {variant === 'bonus' && <VariantBonusBadge />}
          {variant === 'error' && <VariantErrorBadge />}
        </div>

        {/* Content */}
        <div className="flex items-center justify-between gap-x-2">
          <div className="text-default body-sm-medium flex items-center gap-x-1">
            {content}
          </div>

          {extension}
        </div>
      </div>

      {/* Dismiss */}
      <button
        onClick={closeToast}
        className="hover:text-default text-secondary relative inset-y-0 flex h-full min-h-12 w-12 items-center justify-center transition-colors"
      >
        <CloseIcon className="size-4" />
      </button>
    </div>
  );

  // Create random id for toast
  const id = Math.random().toString(36).substr(2, 9);

  return reactToastify(renderToast, {
    toastId: id,
    autoClose: duration,
    hideProgressBar: true,
    bodyClassName: '!p-0 overflow-hidden h-full',
    style: {
      padding: 0,
      width: 'calc(100% - 1rem)',
      marginBottom: '0.5rem',
    },
    closeButton: false,
  });
};

const VariantBadge = ({
  className,
  children,
}: {
  className: string;
  children: string;
}) => {
  return (
    <div
      className={cn(
        'font-condensed flex items-center rounded border p-1 text-[10px] font-medium leading-none tracking-[0.06rem]',
        className
      )}
    >
      {children}
    </div>
  );
};

const VariantOnchainBadge = () => (
  <VariantBadge className="border-interactiveActive text-accent">
    ONCHAIN
  </VariantBadge>
);

const VariantBoostBadge = () => (
  <VariantBadge className=" border-blue6 text-blue6">BOOST</VariantBadge>
);

const VariantBonusBadge = () => (
  <VariantBadge className=" border-blue6 text-blue6">BONUS</VariantBadge>
);

const VariantErrorBadge = () => (
  <VariantBadge className="border-interactiveCritical text-critical">
    ERROR
  </VariantBadge>
);
