import { cva, VariantProps } from 'class-variance-authority';
import { forwardRef, ReactNode } from 'react';
import { ArrowOutwardIcon } from '../../icons';
import { cn } from '../../utils';
import { Link, LinkProps } from './Link';

const titleLinkVariants = cva(
  'pressed:ring rounded-sm title-xs-medium flex max-w-fit items-center justify-between gap-x-0.5 hover:text-default transition-colors',
  {
    variants: {
      prominence: {
        high: 'text-accent fill-accent',
        default: 'text-secondary fill-secondary',
        subtle: 'text-subtle fill-subtle',
        inherit: 'text-inherit fill-inherit',
      },
    },
    defaultVariants: {
      prominence: 'default',
    },
  }
);

export interface TitleLinkProps
  extends VariantProps<typeof titleLinkVariants>,
    LinkProps {
  children: ReactNode;
  isExternalLink?: boolean;
}

/**
 * @deprecated Use `TextLink` instead.
 */
export const TitleLink = forwardRef<HTMLAnchorElement, TitleLinkProps>(
  ({ className, isExternalLink, prominence, ...props }, ref) => {
    return isExternalLink ? (
      <Link
        target="_blank"
        className={cn(titleLinkVariants({ prominence }), className)}
        {...props}
        ref={ref}
      >
        <span>{props.children}</span>
        <ArrowOutwardIcon className="size-4" />
      </Link>
    ) : (
      <Link
        className={cn(titleLinkVariants({ prominence }), className)}
        {...props}
        ref={ref}
      >
        <span>{props.children}</span>
      </Link>
    );
  }
);

TitleLink.displayName = 'TitleLink';
