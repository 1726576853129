import { createIcon } from '../createIcon';

export const IncrementDecrementIcon = createIcon(
  <>
    <path d="M8.58653 18.9235L4.41348 14.7504L5.49806 13.6658L8.58653 16.7543L11.675 13.6658L12.7596 14.7504L8.58653 18.9235ZM5.49806 9.97347L4.41348 8.88887L8.58653 4.71582L12.7596 8.88887L11.675 9.97347L8.58653 6.88499L5.49806 9.97347Z" />
    <path d="M17.5865 19.2841H16.3942V17.2841H14.3942V16.0918H16.3942V14.0918H17.5865V16.0918H19.5865V17.2841H17.5865V19.2841Z" />
    <path d="M19.3365 6.48406H14.6442V5.29176H19.3365V6.48406Z" />
  </>,
  'IncrementDecrementIcon'
);
