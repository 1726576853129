import { useMemo } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { Radio, RadioGroup } from 'react-aria-components';

import tokens from '../../styles/tokens';
import { cn } from '../../utils';
import { CONTROL_HEIGHT, DOT_RADIUS } from './constants';
import { type PeriodKey, DEFAULT_PERIODS, PERIOD_ITEMS } from './periods';

const defaultAllowedKeys = new Set(DEFAULT_PERIODS);

type PeriodControlProps = {
  allowedKeys?: Set<PeriodKey>;
  isHidden?: boolean;
  onChange: (value: PeriodKey) => void;
  value: PeriodKey;
};

export function PeriodControl(props: PeriodControlProps) {
  const { allowedKeys = defaultAllowedKeys, isHidden, onChange, value } = props;
  const items = useMemo(() => {
    return PERIOD_ITEMS.filter((item) => allowedKeys.has(item.key));
  }, [allowedKeys]);
  return (
    <RadioGroup
      aria-label="range"
      onChange={(value) => {
        onChange(value as PeriodKey);
      }}
      value={value}
      className="body-base-normal flex min-w-0 justify-center overflow-x-auto transition-opacity"
      style={{
        height: CONTROL_HEIGHT,
        opacity: isHidden ? 0 : 1,
        paddingInline: DOT_RADIUS,
        // @ts-expect-error CSS Variable declaration is valid
        '--separator-width': '1px',
      }}
    >
      {items.map((item, index) => (
        <Fragment key={item.key}>
          {!!index && (
            <div
              role="presentation"
              style={{
                alignSelf: 'center',
                borderInlineStart: `var(--separator-width) solid ${tokens.textColor.default}`,
                height: '1em',
                opacity: 0.2,
              }}
            />
          )}
          <Radio
            aria-label={item.label}
            value={item.key}
            className={cn(
              'text-secondary flex min-w-12 items-center justify-center rounded-sm px-4 text-center',
              'hover:text-default',
              'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-[-2px] focus-visible:outline-current',
              'selected:bg-surfaceTwo selected:text-default selected:font-medium'
            )}
            style={{
              marginInline: 'calc(var(--separator-width) * -1)',
              zIndex: 1,
            }}
          >
            <span className="hidden md:inline">{item.label}</span>
            <span className="md:hidden">{item.compactLabel}</span>
          </Radio>
        </Fragment>
      ))}
    </RadioGroup>
  );
}
