import { type ReactNode, forwardRef } from 'react';
import {
  type TextFieldProps as RACTextFieldProps,
  TextField as RACTextField,
} from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';
import {
  HelpText,
  FieldLabel,
  FieldProvider,
  Input,
  InputGroup,
} from '../field';

export type TextFieldProps = {
  /** The label of the field. For "visually hidden" labels, use the `aria-label` attribute. */
  label?: string;
  /** The label extension is displayed after the label, in a less prominent font. */
  labelExtension?: string;
  /**
   * Hint text is displayed below the label to give extra context or instruction
   * about what a user should input in the field.
   */
  hint?: string;
  /**
   * Avoid placeholders, which are inaccessible and disappear during input.
   * Prefer `hint` text to offer essential information.
   */
  placeholder?: string;
  /**
   * The size of the text field's input element.
   * @default medium
   */
  size?: 'small' | 'medium' | 'large';
  /** Element to display before the input. */
  startElement?: ReactNode;
  /** Element to display after the input. */
  endElement?: ReactNode;
} & Omit<RACTextFieldProps, 'children' | 'isInvalid' | 'className' | 'style'> &
  StyleProps;

// ^ RAC omissions:
// - children, className, and style: render props not supported
// - isInvalid: we don't (yet) support realtime validation

/**
 * Text fields allow users to input text with a keyboard. Use when the expected
 * input is a single line of text.
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(props, forwardedRef) {
    const {
      className,
      endElement,
      hint,
      label,
      labelExtension,
      size = 'medium',
      startElement,
      ...otherProps
    } = props;

    // unlikely but better to warn
    if (labelExtension && !label) {
      console.warn('The `labelExtension` should accompany a `label`.');
    }

    return (
      <RACTextField
        className={cn('flex w-full flex-col items-start gap-2', className)}
        {...otherProps}
      >
        {(renderProps) => (
          <FieldProvider size={size} {...renderProps}>
            {label || labelExtension ? (
              <FieldLabel extension={labelExtension}>{label}</FieldLabel>
            ) : null}

            <InputGroup>
              {startElement}
              <Input ref={forwardedRef} />
              {endElement}
            </InputGroup>

            <HelpText description={hint}>
              {({ validationErrors }) => validationErrors.join(' ')}
            </HelpText>
          </FieldProvider>
        )}
      </RACTextField>
    );
  }
);
