import { motion, useAnimationControls } from 'framer-motion';
import { useEffect } from 'react';

import PatronSaleCelebrationLighting from 'assets/images/patron-sale/CelebrationLighting.png';
import PatronSaleCardImage from 'assets/images/patron-sale/PatronNFTAsset.png';

export default function PatronNftImage() {
  const imageAnimationControls = useAnimationControls();

  useEffect(() => {
    imageAnimationControls?.start({
      opacity: 1,
      rotateY: 0,
      rotateZ: 5,
      translateY: 0,
      transition: {
        opacity: {
          delay: 1,
          ease: 'easeOut',
          duration: 2,
        },
        rotateY: {
          delay: 1,
          ease: 'easeOut',
          duration: 2,
        },
        rotateZ: {
          delay: 1,
          ease: 'easeOut',
          duration: 4,
        },
        translateY: {
          delay: 1,
          ease: 'easeOut',
          duration: 4,
        },
      },
    });

    setTimeout(() => {
      imageAnimationControls?.start({
        translateY: [0, -35, 0],
        rotateY: [0, -20, 20, 0],
        transition: {
          translateY: {
            repeat: Infinity,
            duration: 5,
            ease: 'easeInOut',
          },
          rotateY: {
            repeat: Infinity,
            duration: 5,
            ease: 'easeInOut',
          },
        },
      });
    }, 4000 + 1000);
  }, [imageAnimationControls]);

  return (
    <div className="pointer-events-none flex  w-full items-center justify-center gap-6  max-sm:mt-28">
      <div
        className="relative"
        style={{
          perspective: '1000px',
        }}
      >
        <motion.img
          src={PatronSaleCelebrationLighting}
          alt="Patron Sale Card"
          className="pointer-events-none z-[2]"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              delay: 0.25,
              ease: 'easeIn',
              duration: 1.5,
            },
          }}
        />
        <motion.img
          src={PatronSaleCardImage}
          alt="Patron Sale Card"
          className="pointer-events-none absolute bottom-16 left-1/2 z-[3] w-32 -translate-x-1/2 sm:w-36"
          initial={{
            opacity: 0,
            rotateY: 75,
            rotateZ: 10,
            translateY: -400,
            translateX: '-50%',
          }}
          animate={imageAnimationControls}
          style={{
            transformStyle: 'preserve-3d',
          }}
        />
      </div>
    </div>
  );
}
