import { filterDOMProps } from '@react-aria/utils';
import type { DOMProps } from '@react-types/shared';
import {
  type ForwardedRef,
  type ReactNode,
  type SVGAttributes,
  createContext,
  forwardRef,
} from 'react';
import {
  type ContextValue,
  type SlotProps,
  useContextProps,
} from 'react-aria-components';

import { type StyleProps, cn } from '../utils';

export type IconContextValue = {
  // TODO: add size prop
} & SlotProps;

export const IconContext = createContext<
  ContextValue<SVGAttributes<SVGElement>, SVGSVGElement>
>({});

/**
 * TODO: Improve with a `size` prop when design team has defined sizes.
 */
type IconProps = {
  /**
   * Defines a string value that labels the current element.
   */
  'aria-label'?: string;
} & DOMProps &
  SlotProps &
  StyleProps;

export const createIcon = (path: ReactNode, name: string) => {
  const Icon = forwardRef(
    (props: IconProps, ref: ForwardedRef<SVGSVGElement>) => {
      [props, ref] = useContextProps(
        { ...props, slot: props.slot || 'icon' },
        ref,
        IconContext
      );
      const { className, style, ...otherProps } = props;
      const hasAriaLabel = !!otherProps['aria-label'];

      return (
        <svg
          {...filterDOMProps(otherProps, { labelable: true })}
          ref={ref}
          aria-hidden={hasAriaLabel ? undefined : true}
          fill="currentColor"
          focusable="false"
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={cn('size-6 flex-none', className)}
          style={style}
        >
          {path}
        </svg>
      );
    }
  );

  // For nicer output in React Dev Tools
  Icon.displayName = name;

  return Icon;
};
