import { useContext } from 'react';
import {
  ModalContext,
  OverlayTriggerStateContext,
  useSlottedContext,
} from 'react-aria-components';

/**
 * A convenience hook to access dialog state. Useful in situations where a
 * component is shared between dialogs, or state must be composed with other
 * hooks. For most cases, it’s simpler to provide a render prop to the `Dialog`
 * component.
 */
export function useDialogState() {
  const modalContext = useSlottedContext(ModalContext);
  const triggerContext = useContext(OverlayTriggerStateContext);

  if (!modalContext && !triggerContext) {
    throw new Error(
      'Cannot call useDialogState outside a <DialogTrigger> or <DialogMarshal>.'
    );
  }

  return {
    isOpen: triggerContext?.isOpen ?? modalContext?.isOpen,
    close() {
      triggerContext?.close?.();
      modalContext?.onOpenChange?.(false);
    },
  };
}
