import { InfinexLogoFull } from '@infinex/ui/assets';
import { Button, Text } from '@infinex/ui/components';
import { PowerOffIcon, WalletIcon } from '@infinex/ui/icons';
import { AppRoutes } from 'components/router';
import { useClientContext } from 'providers/client';
import { Link } from 'react-router-dom';
import { useUserStore } from 'stores';
import { useDisconnect } from 'wagmi';
import { truncateAddress } from 'utils/utils';

export const Header = () => {
  const { client } = useClientContext();

  const logout = useUserStore((state) => state.logout);
  const session = useUserStore((state) => state.session);

  const { disconnect } = useDisconnect();

  async function handleLogout() {
    await logout(client);
    disconnect();

    // Reconnecting wallet requires a reload due to bug with WalletConnect :(
    window.location.reload();
  }

  return (
    <div className="border-subtle inset-x-0 top-0 z-10 flex min-h-[3.5rem] items-center justify-between border-b px-4 py-3 sm:border-none lg:fixed lg:px-6">
      <div className="flex items-center gap-x-12">
        <Link to={AppRoutes.HOME}>
          <InfinexLogoFull />
        </Link>
      </div>
      {session && (
        <div className="flex items-center gap-x-6">
          <div className="text-slate11 flex items-center gap-x-2">
            <div className="hidden sm:block">
              <WalletIcon />
            </div>
            <p className="body-sm-normal">{truncateAddress(session.address)}</p>
          </div>
          <Button prominence="medium" size="small" onPress={handleLogout}>
            <PowerOffIcon />
            <Text className="hidden sm:block">Disconnect & Exit</Text>
          </Button>
        </div>
      )}
    </div>
  );
};
