import { PublicKey } from '@solana/web3.js';

import {
  ALL_ASSETS,
  Asset,
  AssetId,
  AssetSymbol,
  AssetType,
  ChainAddress,
  CoingeckoId,
} from './assets';
import { ChainKey, getActiveChainKeys } from './chains';

// TODO: Move this to a better place/package?
export type Environment = 'dev' | 'test' | 'staging' | 'prod';

export type ChainAsset = {
  chainAssetId: ChainAssetId;
  assetId: AssetId;
  chain: ChainKey;
  name: string;
  type: AssetType;
  symbol: AssetSymbol;
  decimals: number;
  coingeckoId?: CoingeckoId;
  address: ChainAddress;
};

export type ChainAssetId = `${ChainKey}_${AssetId}`;

type AssetChainAddress = { chain: ChainKey; address: string; decimals: number };

// Custom addresses
const JUMBOS_CAT_BASE_USDE = '0x6F19A12BE1CcCDE8cB14562C56A54521BCCd8862';
const RAMBOS_SOY_FACE_ETHEREUM_USDE =
  '0xe228BEC182D4B0Bd3a4945CF4b7dD1fF77C075c4';
const MEME_TOSHI_BASE = '0xD7b0fAFb427aA66c949471d8164AeBe06a1dF127';
const MEME_MFER_BASE = '0xfe066fA70c4a8d5966Ac796a8A036fc0b2926e25';
const MEME_DEGEN_BASE = '0x8ce19eacEBF5837283e41A260d235dc03cEca0BF';
const MEME_WIF_SEPOLIA = '0xadD4A06a895045fAD148d9eE8F25AE42bB31dF2E';
const MEME_WIF_BASE = '0xB32dD58a9DA00c6f975eC5F71e6231C0BbFc4D80';
const MEME_MEW_BASE = '0xEa29F6Cf7D73e47CF7b525Ce4595F1a902D8DFFc';
const MEME_BONK_BASE = '0x7e9780a3f435cbEd862276f083d8B17412Cc3593';
const MEME_MOG_BASE = '0x842ff6492f6200773E0e354D7204141378b1aC55';
const MEME_MOTHER_BASE = '0xd9053011552785731b0B983B16315790E01a938B';
const MEME_MOUTAI_BASE = '0x1E0584F2a051E73D98a30314A3d026d5cD21d828';
const MEME_SHIB_BASE = '0x7Ea322EF6F6c206f984b77b613b55FAF791DC26C';
const MEME_BRETT_BASE = '0xB95fAA6430Ed53A4C4d1C7Edc54996F12B74e67f';
const MEME_PEPE_BASE = '0x10DF2df8829182201FC189645D780BFc1Df7f23A';
const MEME_FLOKI_BASE = '0xAdD19a736071b82fdE1C8b9c64f0B5A520e28692';
const MEME_POPCAT_BASE = '0x504c4da6e37a4d00166353e83615471747f1D5b8';

const FAKE_JUPSOL_BASE = '0xd1D5E829339a8aB64cCE37369ecDc800E29DCe7d';
const FAKE_INF_BASE = '0x72CA105A70daAC0356525eE4CFF1636dEB3680A8';
const FAKE_HSOL_BASE = '0x5056AaD0ccD98a0329e84bFd651B33CA7EB620ce';
const TEST_INFINEX_GOVERNANCE_POINTS_BASE =
  '0x1771C35Ce1D36493baAe40670e06C8979E5Ab007';

const FAKE_PYTH_SOLANA = 'A6Fci5aMjhv2hx349ptrMh3dE5YTQw5AAqUBmYpSNdcQ';
const FAKE_RENZO_BASE = '0x1FE31B5fec4Ee70BE6951517f76E32bCAe59FB26';

// I have minted 500 to Meme wallet. Import enumerating from 1 -->
const FAKE_LIL_PUDGYS_SEPOLIA = '0x5056AaD0ccD98a0329e84bFd651B33CA7EB620ce';
const FAKE_PATRON_NFT_SEPOLIA = '0x68BB89446Fe7a00c8a0f81712885F07519a836D5';

const TEST_ADDRESSES: Record<AssetId, AssetChainAddress[]> = {
  eth: [
    {
      chain: 'arbitrum',
      address: 'native',
      decimals: 18,
    },
    { chain: 'base', address: 'native', decimals: 18 },
    { chain: 'ethereum', address: 'native', decimals: 18 },
    { chain: 'optimism', address: 'native', decimals: 18 },
  ],
  usd_coin: [
    {
      chain: 'arbitrum',
      address: '0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d',
      decimals: 6,
    },
    {
      chain: 'base',
      address: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
      decimals: 6,
    },
    {
      chain: 'ethereum',
      address: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
      decimals: 6,
    },
    {
      chain: 'optimism',
      address: '0x5fd84259d66Cd46123540766Be93DFE6D43130D7',
      decimals: 6,
    },
    {
      chain: 'polygon',
      address: '0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582',
      decimals: 6,
    },
    {
      chain: 'solana',
      address: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
      decimals: 6,
    },
  ],
  usd_ethena: [
    {
      chain: 'arbitrum',
      address: '0x2E6Ac9bB15f5d18E6695C7d860e56Eb94a5fBf70',
      decimals: 18,
    },
    { chain: 'base', address: JUMBOS_CAT_BASE_USDE, decimals: 18 },
    {
      chain: 'ethereum',
      address: RAMBOS_SOY_FACE_ETHEREUM_USDE,
      decimals: 18,
    },
  ],
  usd_blast: [
    {
      chain: 'blast',
      address: '0x4200000000000000000000000000000000000022',
      decimals: 18,
    },
  ],
  usd_tether: [],
  sol: [
    {
      chain: 'solana',
      address: 'native',
      decimals: 9,
    },
  ],
  usd_coin_base_bridged: [],
  usd_coin_ethereum_bridged: [],
  eth_lido_staked: [],
  eth_lido_staked_wrapped: [],
  eth_wrapped: [],
  sol_jito_staked: [],
  eth_renzo_staked: [{ chain: 'base', address: FAKE_RENZO_BASE, decimals: 18 }],
  op: [],
  dai: [],
  arb: [],
  dai_savings: [],
  dog_wif_hat: [
    { chain: 'ethereum', address: MEME_WIF_SEPOLIA, decimals: 6 },
    { chain: 'base', address: MEME_WIF_BASE, decimals: 6 },
  ],
  bonk: [{ chain: 'base', address: MEME_BONK_BASE, decimals: 5 }],
  cat_in_a_dogs_world: [{ chain: 'base', address: MEME_MEW_BASE, decimals: 5 }],
  mog_coin: [{ chain: 'base', address: MEME_MOG_BASE, decimals: 18 }],
  mother_iggy: [{ chain: 'base', address: MEME_MOTHER_BASE, decimals: 6 }],
  moutai: [{ chain: 'base', address: MEME_MOUTAI_BASE, decimals: 6 }],
  toshi: [{ chain: 'base', address: MEME_TOSHI_BASE, decimals: 18 }],
  mfer: [{ chain: 'base', address: MEME_MFER_BASE, decimals: 18 }],
  shiba_inu: [{ chain: 'base', address: MEME_SHIB_BASE, decimals: 18 }],
  brett: [{ chain: 'base', address: MEME_BRETT_BASE, decimals: 9 }],
  pepe: [{ chain: 'base', address: MEME_PEPE_BASE, decimals: 18 }],
  degen: [{ chain: 'base', address: MEME_DEGEN_BASE, decimals: 18 }],
  floki: [{ chain: 'base', address: MEME_FLOKI_BASE, decimals: 9 }],
  popcat: [{ chain: 'base', address: MEME_POPCAT_BASE, decimals: 9 }],
  sol_blazestake_staked: [],
  eth_dinero_staked: [],
  eth_dinero_staked_yield: [],
  pyth_network: [{ chain: 'solana', address: FAKE_PYTH_SOLANA, decimals: 6 }],
  uniswap: [],
  chainlink: [],
  aave: [],
  synthetix: [],
  maker: [],
  velodrome: [],
  aerodrome: [],
  curve_dao: [],
  convex_finance: [],
  ondo_finance: [],
  pendle: [],
  ethena: [],
  layerzero: [],
  jupiter: [],
  raydium: [],
  jito: [],
  wormhole: [],
  eth_rocket_pool: [],
  sol_jupiter_staked: [
    {
      chain: 'base',
      address: FAKE_JUPSOL_BASE,
      decimals: 9,
    },
  ],
  sol_socean_staked: [
    {
      chain: 'base',
      address: FAKE_INF_BASE,
      decimals: 9,
    },
  ],
  lil_pudgys: [
    {
      chain: 'ethereum',
      address: FAKE_LIL_PUDGYS_SEPOLIA,
      decimals: 0,
    },
  ],
  sappy_seals: [],
  solblaze: [],
  dinero: [],
  sol_helius_staked: [
    {
      chain: 'base',
      address: FAKE_HSOL_BASE,
      decimals: 9,
    },
  ],
  immutable: [],
  beam: [],
  gala: [],
  axie_infinity: [],
  the_sandbox: [],
  apecoin: [],
  stepn: [],
  illuvium: [],
  gods_unchained: [],
  aavegotchi: [],
  guild_of_guardians: [],
  echelon_prime: [],
  renzo: [],
  infinex_governance_points: [
    {
      chain: 'base',
      address: TEST_INFINEX_GOVERNANCE_POINTS_BASE,
      decimals: 18,
    },
  ],
  eigenlayer: [],
  patron_nft: [
    {
      chain: 'ethereum',
      address: FAKE_PATRON_NFT_SEPOLIA,
      decimals: 0,
    },
  ],
  spx_6900: [],
  harry_potter_obama_sonic_10_inu: [],
};

const MAIN_ADDRESSES: Record<AssetId, AssetChainAddress[]> = {
  eth: [
    {
      chain: 'arbitrum',
      address: 'native',
      decimals: 18,
    },
    { chain: 'base', address: 'native', decimals: 18 },
    { chain: 'ethereum', address: 'native', decimals: 18 },
    { chain: 'optimism', address: 'native', decimals: 18 },
  ],
  usd_coin: [
    {
      chain: 'arbitrum',
      address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      decimals: 6,
    },
    {
      chain: 'base',
      address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
      decimals: 6,
    },
    {
      chain: 'ethereum',
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      decimals: 6,
    },
    {
      chain: 'optimism',
      address: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
      decimals: 6,
    },
    {
      chain: 'polygon',
      address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
      decimals: 6,
    },
    {
      chain: 'solana',
      address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
      decimals: 6,
    },
  ],
  usd_coin_ethereum_bridged: [
    {
      chain: 'arbitrum',
      address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
      decimals: 6,
    },
    {
      chain: 'optimism',
      address: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
      decimals: 6,
    },
    {
      chain: 'polygon',
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      decimals: 6,
    },
  ],
  usd_ethena: [
    {
      chain: 'arbitrum',
      address: '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34',
      decimals: 18,
    },
    {
      chain: 'ethereum',
      address: '0x4c9EDD5852cd905f086C759E8383e09bff1E68B3',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34',
      decimals: 18,
    },
  ],
  usd_tether: [
    {
      chain: 'arbitrum',
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      decimals: 6,
    },
    {
      chain: 'ethereum',
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      decimals: 6,
    },
    {
      chain: 'optimism',
      address: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
      decimals: 6,
    },
    {
      chain: 'polygon',
      address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      decimals: 6,
    },
    {
      chain: 'solana',
      address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
      decimals: 6,
    },
  ],
  usd_coin_base_bridged: [
    {
      chain: 'base',
      address: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
      decimals: 6,
    },
  ],
  usd_blast: [
    {
      chain: 'blast',
      address: '0x4300000000000000000000000000000000000003',
      decimals: 18,
    },
  ],
  sol: [
    {
      chain: 'solana',
      address: 'native',
      decimals: 9,
    },
  ],
  eth_lido_staked: [
    {
      chain: 'ethereum',
      address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
      decimals: 18,
    },
  ],
  eth_lido_staked_wrapped: [
    {
      chain: 'ethereum',
      address: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0x03b54A6e9a984069379fae1a4fC4dBAE93B3bCCD',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0x5979D7b546E38E414F7E9822514be443A4800529',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452',
      decimals: 18,
    },
  ],
  eth_wrapped: [
    {
      chain: 'ethereum',
      address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0x4200000000000000000000000000000000000006',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x4200000000000000000000000000000000000006',
      decimals: 18,
    },
  ],
  sol_jito_staked: [
    {
      chain: 'solana',
      address: 'J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn',
      decimals: 9,
    },
  ],
  eth_renzo_staked: [
    {
      chain: 'base',
      address: '0x2416092f143378750bb29b79eD961ab195CcEea5',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0x2416092f143378750bb29b79eD961ab195CcEea5',
      decimals: 18,
    },
    {
      chain: 'ethereum',
      address: '0xbf5495efe5db9ce00f80364c8b423567e58d2110',
      decimals: 18,
    },
  ],
  op: [
    {
      chain: 'optimism',
      address: '0x4200000000000000000000000000000000000042',
      decimals: 18,
    },
  ],
  arb: [
    {
      chain: 'arbitrum',
      address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
      decimals: 18,
    },
    {
      chain: 'ethereum',
      address: '0xb50721bcf8d664c30412cfbc6cf7a15145234ad1',
      decimals: 18,
    },
  ],
  dai: [
    {
      chain: 'ethereum',
      address: '0x6b175474e89094c44da98b954eedeac495271d0f',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
      decimals: 18,
    },
  ],
  dai_savings: [
    {
      chain: 'ethereum',
      address: '0x83F20F44975D03b1b09e64809B757c47f942BEeA',
      decimals: 18,
    },
  ],
  dog_wif_hat: [
    {
      chain: 'solana',
      address: 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
      decimals: 6,
    },
  ],
  bonk: [
    {
      chain: 'solana',
      address: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
      decimals: 5,
    },
    {
      chain: 'arbitrum',
      address: '0x09199d9a5f4448d0848e4395d065e1ad9c4a1f74',
      decimals: 5,
    },
    {
      chain: 'polygon',
      address: '0xe5b49820e5a1063f6f4ddf851327b5e8b2301048',
      decimals: 5,
    },
    {
      chain: 'ethereum',
      address: '0x1151cb3d861920e07a38e03eead12c32178567f6',
      decimals: 5,
    },
  ],
  cat_in_a_dogs_world: [
    {
      chain: 'solana',
      address: 'MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5',
      decimals: 5,
    },
  ],
  mog_coin: [
    {
      chain: 'ethereum',
      address: '0xaaee1a9723aadb7afa2810263653a34ba2c21c7a',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0x2da56acb9ea78330f947bd57c54119debda7af71',
      decimals: 18,
    },
  ],
  mother_iggy: [
    {
      chain: 'solana',
      address: '3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN',
      decimals: 6,
    },
  ],
  moutai: [
    {
      chain: 'solana',
      address: '45EgCwcPXYagBC7KqBin4nCFgEZWN7f3Y6nACwxqMCWX',
      decimals: 6,
    },
  ],
  toshi: [
    {
      chain: 'base',
      address: '0xac1bd2486aaf3b5c0fc3fd868558b082a531b2b4',
      decimals: 18,
    },
  ],
  mfer: [
    {
      chain: 'base',
      address: '0xe3086852a4b125803c815a158249ae468a3254ca',
      decimals: 18,
    },
  ],
  brett: [
    {
      chain: 'base',
      address: '0x532f27101965dd16442e59d40670faf5ebb142e4',
      decimals: 18,
    },
  ],
  pepe: [
    {
      chain: 'ethereum',
      address: '0x6982508145454ce325ddbe47a25d4ec3d2311933',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0x25d887ce7a35172c62febfd67a1856f20faebb00',
      decimals: 18,
    },
  ],
  shiba_inu: [
    {
      chain: 'ethereum',
      address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
      decimals: 18,
    },
  ],
  floki: [
    {
      chain: 'ethereum',
      address: '0xcf0c122c6b73ff809c693db761e7baebe62b6a2e',
      decimals: 9,
    },
  ],
  degen: [
    {
      chain: 'base',
      address: '0x4ed4e862860bed51a9570b96d89af5e1b0efefed',
      decimals: 18,
    },
  ],
  popcat: [
    {
      chain: 'solana',
      address: '7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr',
      decimals: 9,
    },
  ],
  eth_dinero_staked: [
    {
      chain: 'ethereum',
      address: '0x04C154b66CB340F3Ae24111CC767e0184Ed00Cc6',
      decimals: 18,
    },
  ],
  eth_dinero_staked_yield: [
    {
      chain: 'ethereum',
      address: '0x9Ba021B0a9b958B5E75cE9f6dff97C7eE52cb3E6',
      decimals: 18,
    },
  ],
  sol_blazestake_staked: [
    {
      chain: 'solana',
      address: 'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1',
      decimals: 9,
    },
  ],
  pyth_network: [
    {
      chain: 'solana',
      address: 'HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3',
      decimals: 6,
    },
    {
      chain: 'ethereum',
      address: '0xeFc0CED4B3D536103e76a1c4c74F0385C8F4Bdd3',
      decimals: 6,
    },
    {
      chain: 'arbitrum',
      address: '0xE4D5c6aE46ADFAF04313081e8C0052A30b6Dd724',
      decimals: 6,
    },
    {
      chain: 'optimism',
      address: '0x99C59ACeBFEF3BBFB7129DC90D1a11DB0E91187f',
      decimals: 6,
    },
    {
      chain: 'base',
      address: '0x4c5d8A75F3762c1561D96f177694f67378705E98',
      decimals: 6,
    },
  ],
  uniswap: [
    {
      chain: 'ethereum',
      address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x6fd9d7AD17242c41f7131d257212c54A0e816691',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0xc3De830EA07524a0761646a6a4e4be0e114a3C83',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0xb33eaad8d922b1083446dc23f610c2567fb5180f',
      decimals: 18,
    },
  ],
  chainlink: [
    {
      chain: 'ethereum',
      address: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0x88Fb150BDc53A65fe94Dea0c9BA0a6dAf8C6e196',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
      decimals: 18,
    },
  ],
  aave: [
    {
      chain: 'ethereum',
      address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0xba5DdD1f9d7F570dc94a51479a000E3BCE967196',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x76FB31fb4af56892A25e32cFC43De717950c9278',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
      decimals: 18,
    },
  ],
  synthetix: [
    {
      chain: 'ethereum',
      address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x8700daec35af8ff88c16bdf0418774cb3d7599b4',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0x22e6966B799c4D5B13BE962E1D117b56327FDa66',
      decimals: 18,
    },
  ],
  maker: [
    {
      chain: 'ethereum',
      address: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
      decimals: 18,
    },
  ],
  velodrome: [
    {
      chain: 'optimism',
      address: '0x9560e827af36c94d2ac33a39bce1fe78631088db',
      decimals: 18,
    },
  ],
  aerodrome: [
    {
      chain: 'base',
      address: '0x940181a94a35a4569e4529a3cdfb74e38fd98631',
      decimals: 18,
    },
  ],
  curve_dao: [
    {
      chain: 'ethereum',
      address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x0994206dfe8de6ec6920ff4d779b0d950605fb53',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0x8Ee73c484A26e0A5df2Ee2a4960B789967dd0415',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      decimals: 18,
    },
  ],
  convex_finance: [
    {
      chain: 'ethereum',
      address: '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
      decimals: 18,
    },
  ],
  ondo_finance: [
    {
      chain: 'ethereum',
      address: '0xfAbA6f8e4a5E8Ab82F62fe7C39859FA577269BE3',
      decimals: 18,
    },
  ],
  pendle: [
    {
      chain: 'ethereum',
      address: '0x808507121B80c02388fAd14726482e061B8da827',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0xBC7B1Ff1c6989f006a1185318eD4E7b5796e66E1',
      decimals: 18,
    },
  ],
  ethena: [
    {
      chain: 'ethereum',
      address: '0x57e114B691Db790C35207b2e685D4A43181e6061',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0x58538e6A46E07434d7E7375Bc268D3cb839C0133',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x58538e6A46E07434d7E7375Bc268D3cb839C0133',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0x58538e6A46E07434d7E7375Bc268D3cb839C0133',
      decimals: 18,
    },
  ],
  layerzero: [
    {
      chain: 'ethereum',
      address: '0x6985884C4392D348587B19cb9eAAf157F13271cd',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0x6985884C4392D348587B19cb9eAAf157F13271cd',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x6985884C4392D348587B19cb9eAAf157F13271cd',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0x6985884C4392D348587B19cb9eAAf157F13271cd',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0x6985884C4392D348587B19cb9eAAf157F13271cd',
      decimals: 18,
    },
  ],
  jupiter: [
    {
      chain: 'solana',
      address: 'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN',
      decimals: 6,
    },
  ],
  raydium: [
    {
      chain: 'solana',
      address: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
      decimals: 6,
    },
  ],
  jito: [
    {
      chain: 'solana',
      address: 'jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL',
      decimals: 9,
    },
  ],
  wormhole: [
    {
      chain: 'solana',
      address: '85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ',
      decimals: 6,
    },
    {
      chain: 'ethereum',
      address: '0xb0ffa8000886e57f86dd5264b9582b2ad87b2b91',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0xb0ffa8000886e57f86dd5264b9582b2ad87b2b91',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0xb0ffa8000886e57f86dd5264b9582b2ad87b2b91',
      decimals: 18,
    },
  ],
  eth_rocket_pool: [
    {
      chain: 'ethereum',
      address: '0xae78736cd615f374d3085123a210448e74fc6393',
      decimals: 18,
    },
    {
      chain: 'arbitrum',
      address: '0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8',
      decimals: 18,
    },
    {
      chain: 'optimism',
      address: '0x9bcef72be871e61ed4fbbc7630889bee758eb81d',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0xb6fe221fe9eef5aba221c348ba20a1bf5e73624c',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0x0266f4f08d82372cf0fcbccc0ff74309089c74d1',
      decimals: 18,
    },
  ],
  sol_jupiter_staked: [
    {
      chain: 'solana',
      address: 'jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v',
      decimals: 9,
    },
  ],
  sol_socean_staked: [
    {
      chain: 'solana',
      address: '5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm',
      decimals: 9,
    },
  ],
  lil_pudgys: [
    {
      chain: 'ethereum',
      address: '0x524cab2ec69124574082676e6f654a18df49a048',
      decimals: 0,
    },
  ],
  sappy_seals: [
    {
      chain: 'ethereum',
      address: '0x364C828eE171616a39897688A831c2499aD972ec',
      decimals: 0,
    },
  ],
  dinero: [
    {
      chain: 'ethereum',
      address: '0x6df0e641fc9847c0c6fde39be6253045440c14d3',
      decimals: 18,
    },
  ],
  solblaze: [
    {
      chain: 'solana',
      address: 'BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA',
      decimals: 9,
    },
  ],
  sol_helius_staked: [
    {
      chain: 'solana',
      address: 'he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A',
      decimals: 9,
    },
  ],
  immutable: [
    {
      chain: 'ethereum',
      address: '0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF',
      decimals: 18,
    },
  ],
  beam: [
    {
      chain: 'ethereum',
      address: '0x62D0A8458eD7719FDAF978fe5929C6D342B0bFcE',
      decimals: 18,
    },
  ],
  gala: [
    {
      chain: 'ethereum',
      address: '0xd1d2eb1b1e90b638588728b4130137d262c87cae',
      decimals: 8,
    },
  ],
  axie_infinity: [
    {
      chain: 'ethereum',
      address: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
      decimals: 18,
    },
  ],
  the_sandbox: [
    {
      chain: 'ethereum',
      address: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0xbbba073c31bf03b8acf7c28ef0738decf3695683',
      decimals: 18,
    },
  ],
  apecoin: [
    {
      chain: 'ethereum',
      address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0xb7b31a6bc18e48888545ce79e83e06003be70930',
      decimals: 18,
    },
  ],
  stepn: [
    {
      chain: 'ethereum',
      address: '0xe3c408bd53c31c085a1746af401a4042954ff740',
      decimals: 8,
    },
    {
      chain: 'polygon',
      address: '0x714db550b574b3e927af3d93e26127d15721d4c2',
      decimals: 8,
    },
    {
      chain: 'solana',
      address: '7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx',
      decimals: 9,
    },
  ],
  illuvium: [
    {
      chain: 'ethereum',
      address: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
      decimals: 18,
    },
  ],
  gods_unchained: [
    {
      chain: 'ethereum',
      address: '0xccc8cb5229b0ac8069c51fd58367fd1e622afd97',
      decimals: 18,
    },
  ],
  aavegotchi: [
    {
      chain: 'ethereum',
      address: '0x3f382dbd960e3a9bbceae22651e88158d2791550',
      decimals: 18,
    },
    {
      chain: 'polygon',
      address: '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0xcd2f22236dd9dfe2356d7c543161d4d260fd9bcb',
      decimals: 18,
    },
  ],
  guild_of_guardians: [
    {
      chain: 'ethereum',
      address: '0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62',
      decimals: 18,
    },
  ],
  echelon_prime: [
    {
      chain: 'ethereum',
      address: '0xb23d80f5fefcddaa212212f028021b41ded428cf',
      decimals: 18,
    },
    {
      chain: 'base',
      address: '0xfa980ced6895ac314e7de34ef1bfae90a5add21b',
      decimals: 18,
    },
  ],
  renzo: [
    {
      chain: 'ethereum',
      address: '0x3b50805453023a91a8bf641e279401a0b23fa6f9',
      decimals: 18,
    },
  ],
  infinex_governance_points: [
    {
      chain: 'base',
      address: '0x139Ead3f466ec41f0648a01A495940c488d297fA',
      decimals: 18,
    },
  ],
  eigenlayer: [
    {
      chain: 'ethereum',
      address: '0xec53bf9167f50cdeb3ae105f56099aaab9061f83',
      decimals: 18,
    },
  ],
  patron_nft: [
    {
      chain: 'ethereum',
      address: '0xB5AF0C7f3885c1007e6699c357566610291585Cb',
      decimals: 0,
    },
  ],
  spx_6900: [
    {
      chain: 'ethereum',
      address: '0xe0f63a424a4439cbe457d80e4f4b51ad25b2c56c',
      decimals: 8,
    },
    {
      chain: 'base',
      address: '0x50dA645f148798F68EF2d7dB7C1CB22A6819bb2C',
      decimals: 8,
    },
    {
      chain: 'solana',
      address: 'J3NKxxXZcnNiMjKw9hYb2K4LUxgwB6t1FtPtQVsv3KFr',
      decimals: 8,
    },
  ],
  harry_potter_obama_sonic_10_inu: [
    {
      chain: 'ethereum',
      address: '0x72e4f9F808C49A2a61dE9C5896298920Dc4EEEa9',
      decimals: 8,
    },
    {
      chain: 'base',
      address: '0x2a06A17CBC6d0032Cac2c6696DA90f29D39a1a29',
      decimals: 8,
    },
  ],
};

const STAGING_ADDRESSES: Record<AssetId, AssetChainAddress[]> = {
  ...MAIN_ADDRESSES,
  infinex_governance_points: [
    // This allows us to override the Infinex Governance Points address for staging
    {
      chain: 'base',
      address: '0x11D25A4a8e9dD3a01b0c275311Ce6beb815d97e1',
      decimals: 18,
    },
  ],
  patron_nft: [
    {
      chain: 'ethereum',
      address: '0x0a45E1484F02D2C68bF6f7BBAb2ffB8F18F7fA01',
      decimals: 0,
    },
  ],
};

// Only USDC for bridging
const bridgeEnabledAssets: ChainAssetId[] = [
  'arbitrum_usd_coin',
  'base_usd_coin',
  'ethereum_usd_coin',
  'optimism_usd_coin',
  'polygon_usd_coin',
  'solana_usd_coin',
];

// What *doesn't* show up in DepositWizard.tsx
// const depositDisabled: ChainAssetId[] = [
//   'solana_sol',
//   'ethereum_eth',
//   'arbitrum_eth',
//   'base_eth',
//   'optimism_eth',
// ];

export interface SupportedActions {
  deposit: ReadonlyArray<ChainAssetId>;
  withdraw: ReadonlyArray<ChainAssetId>;
  send: ReadonlyArray<ChainAssetId>;
  bridge: ReadonlyArray<ChainAssetId>;
}

function getSupportedChainAssetActions(
  chainAssetIds: ChainAssetId[]
): SupportedActions {
  return {
    deposit: chainAssetIds,
    withdraw: chainAssetIds,
    send: chainAssetIds,
    bridge: bridgeEnabledAssets,
  };
}

export type ChainAssetOverrides = Partial<
  Record<ChainAssetId, Partial<ChainAsset>>
>;

export function getSupportedChainAssets(
  env: Environment,
  assetOverrides?: ChainAssetOverrides
): {
  supportedChainAssets: ChainAsset[];
  supportedChainAssetActions: SupportedActions;
} {
  const testing = ['dev', 'test'].includes(env);
  const staging = env === 'staging';
  const addresses = testing
    ? TEST_ADDRESSES
    : staging
      ? STAGING_ADDRESSES
      : MAIN_ADDRESSES;

  const chainKeys = getActiveChainKeys(env);
  let chainAssets = ALL_ASSETS.flatMap((a) => {
    return addresses[a.id as AssetId].map((ca) => assetToChainAsset(a, ca));
  }).filter((a) => !!a.address) as ChainAsset[];

  if (assetOverrides !== undefined) {
    chainAssets = handleOverrides(chainAssets, assetOverrides);
  }

  const chainAssetIds = chainAssets
    .filter((ca) => chainKeys.includes(ca.chain))
    .map((a) => a.chainAssetId);

  return {
    supportedChainAssets: chainAssets.sort((a, b) =>
      a.symbol.localeCompare(b.symbol)
    ),
    supportedChainAssetActions: getSupportedChainAssetActions(chainAssetIds),
  };
}

function handleOverrides(
  chainAssets: ChainAsset[],
  assetOverrides: ChainAssetOverrides
): ChainAsset[] {
  // Transform to dictionary
  const caDict = chainAssets.reduce(
    (acc, ca) => {
      acc[ca.chainAssetId] = ca;
      return acc;
    },
    {} as Record<ChainAssetId, ChainAsset>
  );

  // Overrides and Additions
  Object.entries(assetOverrides).forEach(([key, ca]) => {
    const caId = key as ChainAssetId;

    // Ensure a valid override is provided
    const [chainIdFromKey, assetIdFromKey] = splitOnFirstUnderscore(caId);

    if (
      (ca.chain && chainIdFromKey !== ca.chain) ||
      (ca.assetId && ca.assetId !== assetIdFromKey)
    ) {
      throw new Error(
        `Invalid Asset Overide: key does not match override data`
      );
    }

    // if not found, add it
    if (!caDict[caId]) {
      const asset = findAssetByChainAsset(caId, ca);

      if (!asset || !ca.address || !ca.chain)
        throw new Error(`Invalid Asset Overide: Asset not found for ${caId}`);

      caDict[caId] = assetToChainAsset(
        asset,
        ca as AssetChainAddress & Partial<ChainAsset>
      );

      // else override
    } else {
      caDict[caId] = { ...caDict[caId], ...ca };
    }
  });

  return Object.values(caDict);
}

export function getChainAssetMap(
  chainAssets: ChainAsset[]
): Map<ChainAssetId, ChainAsset> {
  return chainAssets.reduce((acc, ca) => {
    acc.set(ca.chainAssetId, ca);
    return acc;
  }, new Map());
}

export function assetToChainAsset(
  asset: Asset,
  chainAsset: AssetChainAddress & Omit<Partial<ChainAsset>, 'address'>
): ChainAsset {
  const { id, ...rest } = asset;
  return {
    chainAssetId: `${chainAsset.chain}_${id}`,
    assetId: id,
    ...rest,
    ...chainAsset,
    address:
      chainAsset.chain === 'solana' && chainAsset.address !== 'native'
        ? new PublicKey(chainAsset.address.toString())
        : chainAsset.address,
  } as ChainAsset;
}

export function getChainAsset(
  chainAssets: ChainAsset[],
  search:
    | { chainAssetId: ChainAssetId }
    | { assetId: AssetId; chain: ChainKey }
    | { address: string; chain: ChainKey }
): ChainAsset | undefined {
  if ('chainAssetId' in search) {
    return chainAssets.find((a) => a.chainAssetId === search.chainAssetId);
  } else if ('address' in search) {
    return chainAssets.find(
      (a) =>
        a.address.toString() === search.address.toString() &&
        a.chain === search.chain
    );
  } else {
    return chainAssets.find(
      (a) => a.assetId === search.assetId && a.chain === search.chain
    );
  }
}

function splitOnFirstUnderscore(str: string) {
  return str.split(/_(.*)/s);
}

/**
 * @example getChainFromChainAssetId('ethereum_usd_coin') === 'ethereum'
 */
export function getChainFromChainAssetId(chainAssetId: ChainAssetId): ChainKey {
  // this type assertion
  return splitOnFirstUnderscore(chainAssetId)[0] as ChainKey;
}

export function findAssetByChainAsset(
  chainAssetId: ChainAssetId,
  ca: Partial<ChainAsset>
): Asset | null {
  const searchId = ca.assetId ?? splitOnFirstUnderscore(chainAssetId)[1];
  return ALL_ASSETS.find((a) => searchId === a.id) ?? null;
}

export function getChainAssetByAddress(
  chainAssets: ChainAsset[],
  chain: ChainKey,
  address: string
): ChainAsset | null {
  return (
    chainAssets.find(
      (a) =>
        a.chain === chain &&
        (a.address === address ||
          (a.address as PublicKey).toString() === address)
    ) || null
  );
}

export function getAssetsFromChainAssets(
  chainAssets: ChainAsset[],
  includePriceId = false
): Asset[] {
  const uniqueAssets = new Set(
    chainAssets.map(({ assetId, name, symbol, type, coingeckoId }) =>
      JSON.stringify({
        id: assetId,
        name,
        symbol,
        type,
        coingeckoId: includePriceId ? coingeckoId : undefined,
      })
    )
  );

  return Array.from(uniqueAssets).map((a) => JSON.parse(a)) as Asset[];
}
