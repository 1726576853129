import { EvmChainKey, SolanaChainKey } from '@infinex/asset-config';
import { Connection } from '@solana/web3.js';
import { type Address } from 'viem';

import { CustomAsset, CustomAssetLookup } from '../types/metadata';
import { getEvmTokenMetadata } from './getEvmTokenMetadata';
import { getSolanaTokenMetadata } from './getSolanaTokenMetadata';
import { type EvmClient } from '../types';

export const UNKNOWN_ASSET_METADATA = {
  name: 'UNKNOWN',
  symbol: 'UNKNOWN',
  decimals: undefined,
};

export async function getTokenMetadata({
  rpcClients,
  heliusApiUrl,
  assetLookups,
}: {
  rpcClients: Record<EvmChainKey, EvmClient> &
    Record<SolanaChainKey, Connection>;
  heliusApiUrl: string;
  assetLookups: CustomAssetLookup[];
}): Promise<CustomAsset[]> {
  const entries = await Promise.all(
    assetLookups.map(async ({ chain, address }: CustomAssetLookup) => {
      if (address === 'native') {
        throw new Error('Native token metadata does not need to be fetched');
      }

      if (chain === 'solana') {
        const meta = await getSolanaTokenMetadata({
          heliusApiUrl,
          address,
        });
        return {
          chain,
          address,
          ...meta,
        };
      } else {
        const meta = await getEvmTokenMetadata({
          client: rpcClients[chain],
          tokenContractAddress: address as Address,
        });
        return {
          chain,
          address,
          ...meta,
        };
      }
    })
  );

  return entries;
}

// I cant import and use ethers in here...
export function bigintToDecimal(value: bigint, decimals: number): number {
  const divisor = BigInt(10 ** decimals);
  const integerPart = value / divisor;
  const fractionalPart = value % divisor;

  // Convert fractional part to string with leading zeros
  const fractionalStr = fractionalPart.toString().padStart(decimals, '0');

  return Number(`${integerPart}.${fractionalStr}`);
}
