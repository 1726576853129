import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useUserStore } from '../stores';

interface Context {
  isLoggedIn: boolean;
}

const authContext = createContext<Context | undefined>(undefined);

export const useAuthContext = () => {
  const context = useContext(authContext);
  if (!context) throw new Error('Auth context must be used in a AuthProvider');
  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const userState = useUserStore((state) => state.session);

  const isLoggedIn = useMemo(() => !!userState, [userState]);

  return (
    <authContext.Provider
      value={{
        isLoggedIn,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
