import { Client, createClient } from '@infinex/client';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ARBITRUM_RPC,
  BASE_RPC,
  BLAST_RPC,
  ETHEREUM_RPC,
  INFINEX_ENVIRONMENT,
  OPTIMISM_RPC,
  POLLING_INTERVAL,
  POLYGON_RPC,
  SOLANA_RPC,
  WEBAUTHN_RPID,
  WORKER_MAIN_URL,
  WORKER_MAIN_WS_URL,
} from 'config';
import { useUserStore } from 'stores';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export type { Client };

interface Context {
  client: Client;
}

const ClientContext = createContext<Context | undefined>(undefined);

export const useClientContext = () => {
  const context = useContext(ClientContext);
  if (!context)
    throw new Error('Client context must be used in a ClientProvider');
  return context;
};

// Export client instance so we can use it outside of components.
export let client: Client;

const clientConfig = {
  env: INFINEX_ENVIRONMENT,
  url: WORKER_MAIN_URL,
  wsUrl: WORKER_MAIN_WS_URL,
  rpId: WEBAUTHN_RPID,
  pollingInterval: POLLING_INTERVAL,
  chains: {
    arbitrum: { rpcUrl: ARBITRUM_RPC },
    base: { rpcUrl: BASE_RPC },
    blast: { rpcUrl: BLAST_RPC },
    ethereum: { rpcUrl: ETHEREUM_RPC },
    optimism: { rpcUrl: OPTIMISM_RPC },
    polygon: { rpcUrl: POLYGON_RPC },
    solana: { rpcUrl: SOLANA_RPC },
  },
};

export const ClientProvider = ({ children }: PropsWithChildren) => {
  const userStore = useUserStore();
  const userState = useUserStore((state) => state.session);
  const queryClient = useQueryClient();

  // Create client instance immediately
  const newClient = createClient(clientConfig);
  const [localClient, setLocalClient] = useState<Client>(newClient);

  const userStateExists = !!userState;

  const getAuthToken = useCallback(async () => {
    return userStateExists;
  }, [userStateExists]);

  useEffect(() => {
    const newClient = createClient(clientConfig);

    client = newClient;
    setLocalClient(newClient);
  }, [getAuthToken]);

  useQuery(
    {
      queryKey: ['fetchSession'],
      queryFn: () => userStore.fetchSession(client),
      // Refetch every 10 seconds
      refetchInterval: 1000 * 10,
      enabled: !!userState,
    },
    queryClient
  );

  useQuery(
    {
      queryKey: ['fetchVestingSchedule'],
      queryFn: () => userStore.fetchVestingSchedule(client, userState?.address),
      // Refetch every 5 seconds
      refetchInterval: 1000 * 5,
      enabled: !!userState?.address,
    },
    queryClient
  );

  // Memoise client to avoid re-renders.
  // Global variables updates are not detected by React well, hence the local state.
  const ctx = useMemo(() => {
    return {
      client: localClient,
    };
  }, [localClient]);

  return (
    <ClientContext.Provider value={ctx}>{children}</ClientContext.Provider>
  );
};
