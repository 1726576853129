import { createIcon } from '../createIcon';

export const ErrorIcon = createIcon(
  <>
    <path d="M12.75 7.25h-1.5V13h1.5V7.25ZM12 14.711a.781.781 0 0 0-.575.233.781.781 0 0 0-.233.575c0 .229.078.42.233.576a.781.781 0 0 0 .575.232c.229 0 .42-.078.575-.232a.782.782 0 0 0 .233-.576.781.781 0 0 0-.232-.575.781.781 0 0 0-.576-.233Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.998 2.5c1.314 0 2.55.25 3.706.748a9.597 9.597 0 0 1 3.017 2.03 9.592 9.592 0 0 1 2.03 3.016 9.245 9.245 0 0 1 .749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 0 1-2.03 3.018 9.592 9.592 0 0 1-3.016 2.03 9.245 9.245 0 0 1-3.704.749 9.256 9.256 0 0 1-3.705-.748 9.598 9.598 0 0 1-3.017-2.03 9.591 9.591 0 0 1-2.032-3.016 9.245 9.245 0 0 1-.748-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 0 1 2.03-3.018 9.592 9.592 0 0 1 3.016-2.03 9.245 9.245 0 0 1 3.704-.749ZM6.325 6.325C7.875 4.775 9.767 4 12 4c2.233 0 4.125.775 5.675 2.325C19.225 7.875 20 9.767 20 12c0 2.233-.775 4.125-2.325 5.675C16.125 19.225 14.233 20 12 20c-2.233 0-4.125-.775-5.675-2.325C4.775 16.125 4 14.233 4 12c0-2.233.775-4.125 2.325-5.675Z"
    />
  </>,
  'ErrorIcon'
);
