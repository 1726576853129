import { ChainKey } from '@infinex/asset-config';

const env = import.meta.env;

export const requireEnvironmentVariable = (envStr: string): string => {
  const envVar = env[envStr];
  if (typeof envVar === 'undefined')
    throw new Error(`${envStr} required but not found`);
  if (typeof envVar === 'boolean') return `${envVar}`;
  return envVar;
};

export const WALLETCONNECT_PROJECT_ID = requireEnvironmentVariable(
  'VITE_WALLETCONNECT_PROJECT_ID'
);

export const WORKER_MAIN_URL = requireEnvironmentVariable(
  'VITE_WORKER_MAIN_URL'
);
export const WORKER_MAIN_WS_URL = requireEnvironmentVariable(
  'VITE_WORKER_MAIN_WS_URL'
);
export const WEBAUTHN_RPID = requireEnvironmentVariable('VITE_WEBAUTHN_RPID');

export const EVM_CHAIN_ENV = env['VITE_EVM_ENV'];

// RPC URLS
export const ARBITRUM_RPC = env['VITE_ARBITRUM_RPC'] || undefined;
export const BASE_RPC = env['VITE_BASE_RPC'] || undefined;
export const BLAST_RPC = env['VITE_BLAST_RPC'] || undefined;
export const ETHEREUM_RPC = env['VITE_ETH_RPC'] || undefined;
export const OPTIMISM_RPC = env['VITE_OPTIMISM_RPC'] || undefined;
export const POLYGON_RPC = env['VITE_POLYGON_RPC'] || undefined;
export const SOLANA_RPC = env['VITE_SOLANA_RPC'] || undefined;

export const RPC_URLS: Record<ChainKey, string> = {
  arbitrum: ARBITRUM_RPC,
  base: BASE_RPC,
  blast: BLAST_RPC,
  ethereum: ETHEREUM_RPC,
  optimism: OPTIMISM_RPC,
  polygon: POLYGON_RPC,
  solana: SOLANA_RPC,
};

// POLLING INTERVAL
export const POLLING_INTERVAL = parseInt(
  env['VITE_POLLING_INTERVAL'] || '10000'
);

export const POSTHOG_KEY = env['VITE_POSTHOG_KEY'];
export const POSTHOG_PROJECT_ID = env['VITE_POSTHOG_PROJECT_ID'];

export enum Environments {
  DEV = 'dev', //dev
  TESTING = 'test', //test
  STAGING = 'staging',
  PRODUCTION = 'prod', //prod
}
export const INFINEX_ENVIRONMENT = requireEnvironmentVariable(
  'VITE_INFINEX_ENVIRONMENT'
) as Environments;

export const PUBLIC_WEBSITE_URL = requireEnvironmentVariable(
  'VITE_PUBLIC_WEBSITE_URL'
);

export const ORIGIN_URL = requireEnvironmentVariable('VITE_ORIGIN_URL');
