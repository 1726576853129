import { waitForTransactionReceipt, writeContract } from '@wagmi/core';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useAccount, useConfig } from 'wagmi';

import {
  ClaimFailedToast,
  ClaimSuccessToast,
} from 'components/toasts/ClaimToast';
import { useClientContext } from 'providers/client';
import { useUserStore } from 'stores';

import { getEvmSdk } from '../utils/getEvmSdk';

export const useClaim = () => {
  const { evmSdk, evmSdkManifest } = getEvmSdk();
  const { address } = useAccount();
  const { client } = useClientContext();
  const user = useUserStore();
  const config = useConfig();

  const isClaimingEnabled = useFeatureFlagEnabled('claim-patron-dapp') ?? false;

  const claimPatrons = async (
    tier: number,
    setIsLoading: (loading: number | null) => void
  ) => {
    if (!isClaimingEnabled) {
      throw new Error('Claiming is not enabled');
    }

    if (!address) {
      throw new Error('No address found');
    }

    setIsLoading(tier);

    try {
      // Get the claimable amount for the given tier
      const claimableAmount = await evmSdk.chains.ethereum.contracts
        .PatronVesting()
        .read.getClaimableAmount([address, tier]);

      if (claimableAmount < 1n) {
        throw new Error('No claimable amount');
      }

      // Execute the claim transaction
      const txHash = await writeContract(config, {
        address: evmSdkManifest.chains.ethereum.contracts
          .PatronVesting as `0x${string}`,
        abi: evmSdkManifest.abis.PatronVesting,
        functionName: 'claim',
        args: [tier, claimableAmount],
      });

      // Wait for the transaction to be mined
      const finalisedTx = await waitForTransactionReceipt(config, {
        hash: txHash,
      });

      ClaimSuccessToast({
        amount: Number(claimableAmount),
        txHash,
      });

      return finalisedTx;
    } catch (error) {
      console.error('Claim failed:', error);
      ClaimFailedToast();

      throw error;
    } finally {
      // Refresh the user's session and reset the loading state
      await user.fetchSession(client);
      setIsLoading(null);
    }
  };

  // Return the claimPatrons function
  return { claimPatrons };
};
