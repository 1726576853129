import { InfinexLogoFull } from '@infinex/ui/assets';
import { Spinner } from '@infinex/ui/components';

export const LoadingPage = () => {
  return (
    <div className="bg-canvas fixed inset-0">
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-y-8">
          <Spinner fill="accent" size="lg" />
          <InfinexLogoFull />
        </div>
      </div>
    </div>
  );
};
