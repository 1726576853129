'use client';

import { useLayoutEffect } from '@react-aria/utils';
import { type ReactNode, cloneElement, isValidElement, useState } from 'react';

/**
 * Clones a valid React element, with merged props if provided. Returns `null`
 * when the value is not a valid React element.
 */
export function cloneValidElement<Props>(
  value: React.ReactElement<Props> | React.ReactNode,
  props?: Partial<Props> & React.Attributes
) {
  if (!isValidElement(value)) {
    return null;
  }

  return cloneElement(value, props);
}

/**
 * Checks if a specified element exists within a given parent.
 *
 * @note Prefer the CSS `has()` selector where appropriate.
 */
export function useHasChild(
  selector: string,
  parentRef: React.RefObject<HTMLElement>
) {
  const [hasChild, setHasChild] = useState(true);

  useLayoutEffect(() => {
    setHasChild(
      !!(parentRef.current && parentRef.current.querySelector(selector))
    );
  }, [setHasChild, selector, parentRef]);

  return hasChild;
}

/**
 * Check if `node` contains only text content. Used to determine whether the
 * `children` of a component should be wrapped.
 */
export function isReactText(node: ReactNode): node is ReactText {
  // sometimes e.g. `<Comp>{value} content</Comp>` children will be an array,
  // but it‘s still "text" as far as the consumer is concerned
  if (Array.isArray(node)) {
    return node.every(isReactText);
  }

  return typeof node === 'string' || typeof node === 'number';
}
type ReactText = string | number | (string | number)[];
