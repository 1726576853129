import './styles/main.css';

import ReactDOM from 'react-dom/client';

import App from './app';

const element = document.getElementById('app')!;
if (!element)
  throw new Error("Could not get element with id 'app' to mount react-dom to");

ReactDOM.createRoot(element).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
