import { filterDOMProps } from '@react-aria/utils';
import { type DOMProps } from '@react-types/shared';
import { type VariantProps, cva } from 'class-variance-authority';
import { type ForwardedRef, forwardRef, useMemo } from 'react';

import { type StyleProps, cn, randomInteger } from '../../utils';

const MIN_WIDTH = 0.1;
const MAX_WIDTH = 1;

export type SkeletonTextProps = {
  /**
   * The text size of the preview element.
   * @default 'base'
   */
  size?: VariantProps<typeof textVariants>['size'];
  /**
   * The proportional width of the preview element, between `0.1` and `1`. Use
   * a tuple to specify a range from which a random value will be selected.
   * @default 1
   */
  width?: number | [number, number];
} & DOMProps &
  StyleProps;

/** A placeholder representing text, before data is loaded. */
export const SkeletonText = forwardRef(
  (props: SkeletonTextProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      className,
      size = 'base',
      style,
      width: widthProp = MAX_WIDTH,
      ...otherProps
    } = props;

    validateWidth(widthProp);

    const width = useMemo(() => {
      if (Array.isArray(widthProp)) {
        return `${randomInteger(widthProp[0] * 100, widthProp[1] * 100)}%`;
      }

      return `${widthProp * 100}%`;
    }, [widthProp]);

    return (
      <div
        aria-hidden
        ref={ref}
        className={cn(textVariants({ size }), className)}
        style={{ width, ...style }}
        {...filterDOMProps(otherProps)}
      >
        {/* Using "1cap" for height so the replacement content will match the
        preview element’s height as closely as possible, without knowing actual
        font metrics. */}
        <div className="bg-surfaceTwo h-[1cap] rounded-sm">
          {zeroWidthSpace}
        </div>
      </div>
    );
  }
);

/**
 * Validate the width prop to ensure it’s within range. Runtime check
 * required to supplement the loose `number` type.
 */
function validateWidth(value: SkeletonTextProps['width']) {
  if ([value].flat().some((value) => !isValidWidth(value))) {
    throw new TypeError(
      `Invalid width ${JSON.stringify(value)}. Values must be between ${MIN_WIDTH} and ${MAX_WIDTH}.`
    );
  }
}
function isValidWidth(value?: number) {
  if (value == null) return false;
  return value >= MIN_WIDTH && value <= MAX_WIDTH;
}

// Use a zero-width space to accurately calculate the height of the represented
// text, without influencing width.
const zeroWidthSpace = '\u200B';

// Block padding simulates half-leading around the preview element
const textVariants = cva('py-[calc((1lh-1cap)/2)]', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      base: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
      '3xl': 'text-3xl',
      '4xl': 'text-4xl',
      '5xl': 'text-5xl',
      '6xl': 'text-6xl',
      '7xl': 'text-7xl',
      '8xl': 'text-8xl',
      '9xl': 'text-9xl',
    },
  },
  defaultVariants: {
    size: 'base',
  },
});
