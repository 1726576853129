import { ToastContainer as ReactToastifyContainer } from 'react-toastify';

export const ToastContainer = () => {
  return (
    <ReactToastifyContainer
      stacked
      position="bottom-right"
      className="!z-toast !w-[26.25rem] !p-2 max-[480px]:!w-screen"
      toastClassName="!bg-canvas !shadow-xl border border-borderInteractiveSubtle !rounded-md"
    />
  );
};
