import {
  Abi,
  erc20Abi,
  erc20Abi_bytes32,
  getContract,
  hexToString,
} from 'viem';

import { CustomAssetMetadata } from '../types/metadata';
import { type EvmClient } from '../types';

const MAKER_TOKEN_ADDRESS = '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2';

export async function getEvmTokenMetadata<TClient extends EvmClient>({
  client,
  tokenContractAddress,
}: {
  client: TClient;
  tokenContractAddress: `0x${string}`;
}): Promise<CustomAssetMetadata> {
  let abi: Abi = erc20Abi;

  // Maker uses a non-standard ABI, click into the below API for more info
  if (tokenContractAddress === MAKER_TOKEN_ADDRESS) {
    abi = erc20Abi_bytes32;
  }

  const tokenContract = getContract({
    address: tokenContractAddress,
    abi,
    client,
  });

  const [name, symbol, decimals] = await Promise.all([
    tokenContract.read.name(),
    tokenContract.read.symbol(),
    tokenContract.read.decimals(),
  ]);

  // Maker returns Hex encoded strings for name and symbol
  if (tokenContractAddress === MAKER_TOKEN_ADDRESS) {
    return {
      name: hexToString(name as `0x${string}`),
      symbol: hexToString(symbol as `0x${string}`),
      decimals: decimals as number,
    };
  }

  return {
    name: name as string,
    symbol: (symbol as string).toUpperCase(),
    decimals: decimals as number,
  };
}
