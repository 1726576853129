import { type Connection, PublicKey } from '@solana/web3.js';

export async function getSolanaNativeBalance({
  connection,
  accountAddress,
}: {
  connection: Connection;
  accountAddress: PublicKey;
}) {
  const value = await connection.getBalance(accountAddress);

  return value ? BigInt(value) : 0n;
}
