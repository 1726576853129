import { type ForwardedRef, forwardRef } from 'react';

import { type DialogProps, Dialog } from './Dialog';

export type AlertDialogProps = Pick<
  DialogProps,
  'children' | 'isKeyboardDismissDisabled' | 'className' | 'style'
>;

/**
 * Alert dialogs display important information that the user must acknowledge
 * before they may continue.
 */
export const AlertDialog = forwardRef(
  (props: AlertDialogProps, forwardedRef: ForwardedRef<HTMLDivElement>) => {
    return (
      <Dialog
        // NOTE: consumer preferences must be before opinionated props
        {...props}
        isDismissable={false}
        ref={forwardedRef}
        // @ts-expect-error Supported for this specific case, but not exposed in
        // consumer prop types.
        role="alertdialog"
        size="small"
      />
    );
  }
);
