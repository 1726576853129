import { filterDOMProps } from '@react-aria/utils';
import { type DOMProps } from '@react-types/shared';
import { type VariantProps, cva } from 'class-variance-authority';
import { type ForwardedRef, forwardRef } from 'react';

import { type StyleProps, cn } from '../../utils';

export type SkeletonMediaProps = {
  /**
   * The aspect ratio of the element, e.g. `16/9` for video, or `4/3` for a
   * landscape image.
   * @default "1/1"
   */
  aspectRatio?: `${number}/${number}`;
  /**
   * Optional content to display inside the skeleton view. Can be useful for
   * indicating the type of media that will load.
   */
  children?: React.ReactNode;
  /**
   * The height of the expected media represented by the skeleton.
   * @default "auto"
   */
  height?: VariantProps<typeof mediaVariants>['height'];
  /**
   * The shape of the element. Choose a shape that matches the media represented
   * by the skeleton.
   * @default "rounded"
   */
  shape?: VariantProps<typeof mediaVariants>['shape'];
} & DOMProps &
  StyleProps;

/** A placeholder representing media like images, videos, etc. before data is loaded. */
export const SkeletonMedia = forwardRef(
  (props: SkeletonMediaProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      aspectRatio = '1/1',
      children,
      className,
      height = 'auto',
      shape = 'rounded',
      style,
      ...otherProps
    } = props;

    return (
      <div
        aria-hidden
        ref={ref}
        className={cn(mediaVariants({ height, shape }), className)}
        style={{ aspectRatio, ...style }}
        {...filterDOMProps(otherProps)}
      >
        {children}
      </div>
    );
  }
);

const mediaVariants = cva(
  'bg-surfaceTwo flex items-center justify-center shrink-0',
  {
    variants: {
      height: {
        auto: 'h-auto',
        full: 'h-full',
        8: 'h-8',
        9: 'h-9',
        10: 'h-10',
        11: 'h-11',
        12: 'h-12',
        14: 'h-14',
        16: 'h-16',
        20: 'h-20',
        24: 'h-24',
        28: 'h-28',
        32: 'h-32',
        36: 'h-36',
        40: 'h-40',
        44: 'h-44',
        48: 'h-48',
        52: 'h-52',
        56: 'h-56',
        60: 'h-60',
        64: 'h-64',
        72: 'h-72',
        80: 'h-80',
        96: 'h-96',
      },
      shape: {
        square: 'rounded-none',
        rounded: 'rounded-md',
        circle: 'rounded-full',
      },
    },
    defaultVariants: {
      height: 'auto',
      shape: 'rounded',
    },
  }
);
