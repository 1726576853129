import 'react-toastify/dist/ReactToastify.css';
import './styles/main.css';

import { ToastContainer } from '@infinex/ui/components';

import { BreakpointProvider } from '@infinex/ui/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ClientProvider } from './providers/client';
import { AuthProvider } from './providers/auth';
import { Router } from 'components/router';
import { AppKitProvider } from 'providers/wagmi';
import { PostHogProvider } from 'posthog-js/react';
import { POSTHOG_KEY } from 'config';

const queryClient = new QueryClient();

export default () => (
  <QueryClientProvider client={queryClient}>
    <PostHogProvider apiKey={POSTHOG_KEY}>
      <ClientProvider>
        <AppKitProvider>
          <AuthProvider>
            <BreakpointProvider>
              <Router />

              <ToastContainer />
            </BreakpointProvider>
          </AuthProvider>
        </AppKitProvider>
      </ClientProvider>
    </PostHogProvider>
  </QueryClientProvider>
);
