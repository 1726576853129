import { erc20Abi, getContract } from 'viem';
import { type EvmClient } from '../types';

export async function getEvmTokenDecimals<TClient extends EvmClient>({
  client,
  tokenContractAddress,
}: {
  client: TClient;
  tokenContractAddress: `0x${string}`;
}): Promise<number> {
  const tokenContract = getContract({
    address: tokenContractAddress,
    abi: erc20Abi,
    client,
  });

  const decimals = await tokenContract.read.decimals();

  return decimals;
}
