import * as Iron from 'iron-webcrypto';

export async function decrypt<T>(
  encrypted: string,
  password: string
): Promise<T> {
  const decrypted = await Iron.unseal(
    crypto,
    encrypted,
    password,
    Iron.defaults
  );
  return decrypted as T;
}

export async function encrypt(
  data: unknown,
  password: string,
  opts: { expirationSeconds?: number } = {}
): Promise<string> {
  const encrypted = await Iron.seal(crypto, data, password, {
    ...Iron.defaults,
    ttl: opts.expirationSeconds ? opts.expirationSeconds * 1000 : 0,
  });
  return encrypted;
}

export type CompressedKeyPair = {
  publicKey: string;
  privateKey: string;
};

export async function generateKeyPair(): Promise<CryptoKeyPair> {
  return crypto.subtle.generateKey(
    {
      name: 'ECDSA',
      namedCurve: 'P-256',
    },
    !0,
    ['sign', 'verify']
  );
}

export async function generateCompressedKeyPair(): Promise<CompressedKeyPair> {
  const keyPair = await generateKeyPair();

  const publicKey = await crypto.subtle.exportKey('raw', keyPair.publicKey);
  const privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);

  if (!privateKey.d) throw new Error('Expected private key');

  const publicKeyArr = new Uint8Array(publicKey),
    publicKeyLen = publicKeyArr.byteLength;

  const compressedPublicKey = publicKeyArr.slice(0, (1 + publicKeyLen) >>> 1);
  compressedPublicKey[0] = 2 | (1 & publicKeyArr[publicKeyLen - 1]);
  const compressedPrivateKey = Uint8Array.from(
    atob(privateKey.d.replace(/-/g, '+').replace(/_/g, '/')),
    (e) => e.charCodeAt(0)
  );

  return {
    publicKey: formatKey(compressedPublicKey),
    privateKey: formatKey(compressedPrivateKey),
  };
}

function formatKey(buffer: Uint8Array) {
  return [...buffer].map((b) => b.toString(16).padStart(2, '0')).join('');
}
