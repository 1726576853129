import { cva } from 'class-variance-authority';

import { cn } from '../../utils';

export const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center whitespace-nowrap rounded transition-all duration-75 outline-none select-none',
    'ring-offset-background focus-visible:ring-1 focus-visible:ring-offset-2',
    'data-[disabled]:pointer-events-none data-[disabled]:text-disabled data-[disabled]:fill-disabled',
    // icon-only buttons have no padding, to keep them square
    'has-[>svg:only-child]:px-0 has-[>svg:first-child+[role="progressbar"]]:px-0'
  ),
  {
    variants: {
      prominence: {
        high: cn(
          'text-onEmphasisInverse bg-accentEmphasis shadow-buttonAccent',
          'hover:bg-accentEmphasisHover',
          'pressed:bg-accentEmphasis pressed:shadow-none',
          'data-[disabled]:bg-shark5 data-[disabled]:shadow-none',
          'data-[pending]:bg-shark5 data-[pending]:shadow-none'
        ),
        medium: cn(
          'bg-shark4 text-default fill-default shadow-buttonNeutral',
          'hover:brightness-110',
          'pressed:brightness-100 pressed:shadow-none',
          'data-[disabled]:bg-disabled data-[disabled]:shadow-none',
          'data-[pending]:bg-disabled data-[pending]:shadow-none'
        ),
        low: 'bg-shark5 text-default fill-default',
        text: 'bg-none !p-0 !min-h-0 !focus:ring-0 fill-default text-default',
        none: '',
      },
      size: {
        large: 'px-4 min-h-12 min-w-12 title-base-semibold',
        medium: 'px-3 min-h-10 min-w-10 title-sm-semibold',
        small: ' px-2 min-h-8 min-w-8 title-xs-semibold',
      },
    },
    defaultVariants: {
      size: 'medium',
      prominence: 'medium',
    },
  }
);

export const iconSizeClasses = {
  large: 'size-6',
  medium: 'size-5',
  small: 'size-4',
} as const;
export const textSizeClasses = {
  large: 'px-1',
  medium: 'px-1',
  small: 'px-1',
} as const;

export const spinnerSizeMap = {
  large: 'lg',
  medium: 'md',
  small: 'sm',
} as const;
