import { ChainKey } from '@infinex/asset-config';

import { EvmClient, EvmNftMetadata } from '../types';

// Note: To use this method, we presume the client has been initialised with a quicknode RPC url
// Documentation on this RPC method can be found here: https://www.quicknode.com/docs/ethereum/qn_fetchNFTs_v2

const QUICKNODE_MAX_PAGE_SIZE = 40;
const UNKNOWN_NFT_NAME = 'Unknown';

export async function getEthNftsByCollection<TClient extends EvmClient>({
  client,
  nftContractAddresses,
  accountAddress,
}: {
  client: TClient;
  nftContractAddresses: `0x${string}`[];
  accountAddress: `0x${string}`;
}): Promise<Record<string, EvmNftMetadata[]>> {
  if (nftContractAddresses.length === 0) {
    return {};
  }

  // Allows us to use QN types
  const typedClient = client;

  const nfts: EvmNftMetadata[] = [];

  const response = await makeNftRpcRequest(
    typedClient,
    nftContractAddresses,
    accountAddress,
    1
  );

  const assets = response.assets;

  // Get all the other pages at once
  if (response.totalPages > 1) {
    const allRest = await Promise.all(
      Array.from({ length: response.totalPages - 1 }, (_, i) =>
        makeNftRpcRequest(
          typedClient,
          nftContractAddresses,
          accountAddress,
          i + 2
        ).then((r) => r.assets)
      )
    ).then((res) => res.flat());
    assets.push(...allRest);
  }

  nfts.push(
    ...assets.map((asset) => ({
      collectionAddress: asset.collectionAddress,
      collectionTokenId: asset.collectionTokenId,
      name:
        asset.name === ''
          ? `${UNKNOWN_NFT_NAME} #${asset.collectionTokenId}`
          : asset.name,
      chain: 'ethereum' as ChainKey,
      imageUrl: asset.imageUrl,
    }))
  );

  return nfts.reduce(
    (acc, nft) => {
      if (!acc[nft.collectionAddress]) {
        acc[nft.collectionAddress] = [];
      }
      acc[nft.collectionAddress].push(nft);
      return acc;
    },
    {} as Record<string, EvmNftMetadata[]>
  );
}

async function makeNftRpcRequest(
  client: EvmClient,
  nftContractAddresses: `0x${string}`[],
  accountAddress: `0x${string}`,
  page: number
) {
  return await client.request({
    method: 'qn_fetchNFTs',
    params: [
      {
        wallet: accountAddress,
        contracts: nftContractAddresses,
        omitFields: ['traits'], // We don't need traits for now
        page,
        perPage: QUICKNODE_MAX_PAGE_SIZE, // Maximum
      },
    ],
  });
}
