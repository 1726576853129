import { type Connection, PublicKey } from '@solana/web3.js';

export async function getSolanaDecimals({
  connection,
  mint,
}: {
  connection: Connection;
  mint: PublicKey;
}): Promise<number | null> {
  const mintAccountInfo = await connection.getParsedAccountInfo(mint);
  const data = mintAccountInfo?.value?.data as any;

  if (!data?.parsed?.info?.decimals) {
    return null;
  }

  return data.parsed.info.decimals;
}
