import { Button, Text } from '@infinex/ui/components';
import { WalletIcon } from '@infinex/ui/icons';
import { useAppKit } from '@reown/appkit/react';

export default function ConnectButton() {
  const { open } = useAppKit();

  return (
    <Button
      prominence="high"
      size="large"
      onPress={async () => {
        open({ view: 'Connect' });
      }}
      className="w-80"
    >
      <WalletIcon />
      <Text>Connect Wallet</Text>
    </Button>
  );
}
