import { createEvmSdk } from '@infinex/evm-sdk';
import * as manifest from '@infinex/manifest';
import { type EvmSdkManifest } from '@infinex/manifest/types';

import {
  ARBITRUM_RPC,
  BASE_RPC,
  ETHEREUM_RPC,
  OPTIMISM_RPC,
  POLYGON_RPC,
  BLAST_RPC,
  INFINEX_ENVIRONMENT,
} from '../config';

type EvmSdk = ReturnType<
  typeof createEvmSdk<EvmSdkManifest['abis'], EvmSdkManifest['chains']>
>;

export const getEvmSdk = (): {
  evmSdk: EvmSdk;
  evmSdkManifest: EvmSdkManifest;
} => {
  const defaultManifest = (() => {
    const environment = INFINEX_ENVIRONMENT;
    return {
      dev: manifest.test,
      test: manifest.test,
      staging: manifest.staging,
      prod: manifest.prod,
    }[environment];
  })();

  if (!defaultManifest) {
    throw new Error('Default manifest not found');
  }

  const evmSdkManifest: EvmSdkManifest = {
    ...defaultManifest,
    chains: {
      arbitrum: {
        ...defaultManifest.chains.arbitrum,
        config: {
          ...defaultManifest.chains.arbitrum.config,
          rpcUrls: {
            default: {
              http: [ARBITRUM_RPC || ''],
            },
          },
        },
      },
      base: {
        ...defaultManifest.chains.base,
        config: {
          ...defaultManifest.chains.base.config,
          rpcUrls: {
            default: {
              http: [BASE_RPC || ''],
            },
          },
        },
      },
      ethereum: {
        ...defaultManifest.chains.ethereum,
        config: {
          ...defaultManifest.chains.ethereum.config,
          rpcUrls: {
            default: {
              http: [ETHEREUM_RPC || ''],
            },
          },
        },
      },
      optimism: {
        ...defaultManifest.chains.optimism,
        config: {
          ...defaultManifest.chains.optimism.config,
          rpcUrls: {
            default: {
              http: [OPTIMISM_RPC || ''],
            },
          },
        },
      },
      polygon: {
        ...defaultManifest.chains.polygon,
        config: {
          ...defaultManifest.chains.polygon.config,
          rpcUrls: {
            default: {
              http: [POLYGON_RPC || ''],
            },
          },
        },
      },
      blast: {
        ...defaultManifest.chains.blast,
        config: {
          ...defaultManifest.chains.blast.config,
          rpcUrls: {
            default: {
              http: ['https://rpc.blastblockchain.com' || ''],
            },
          },
        },
      },
    },
  };

  const evmSdk = createEvmSdk(evmSdkManifest);

  return {
    evmSdk,
    evmSdkManifest,
  };
};
