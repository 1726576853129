import VestingGraphic from 'assets/images/patron-vesting-schedule.svg';

import VestingScheduleMobile from './VestingScheduleMobile';
import VestingScheduleTable from './VestingScheduleTable';

export type VestingItem = {
  id: number;
  period: number;
  totalPatrons: number;
  startDate: Date;
  endDate: Date;
  vested: number;
  status: 'Vesting' | 'Locked' | 'Vested';
};

export const VestingSchedule = ({
  vestingItems,
}: {
  vestingItems: VestingItem[];
}) => {
  return (
    <div className="border-subtle text-slate11 flex flex-col gap-y-10 rounded-2xl border py-8">
      <div className="px-6 sm:px-10">
        <h1 className="title-base-medium mb-6">Vesting Schedule</h1>
        <p className="body-sm-normal text-subtle">
          Patrons will unlock over two periods based on their vesting
          conditions. Due to the indivisibility of NFTs, you will only be able
          to withdraw your NFT once the entire unit becomes liquid.
        </p>
      </div>

      <div className="overflow-x-scroll">
        <div className="min-w-[740px]">
          <VestingGraphic />
        </div>
      </div>

      <div className="px-6 sm:px-10">
        <div className="hidden sm:block">
          <VestingScheduleTable vestingItems={vestingItems} />
        </div>
        <div className="block sm:hidden">
          <VestingScheduleMobile vestingItems={vestingItems} />
        </div>
      </div>
    </div>
  );
};
