import { createAppKit } from '@reown/appkit/react';

import { http, WagmiProvider } from 'wagmi';
import { AppKitNetwork, mainnet, sepolia } from '@reown/appkit/networks';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { PropsWithChildren } from 'react';
import { ORIGIN_URL, EVM_CHAIN_ENV, WALLETCONNECT_PROJECT_ID } from 'config';
import { createSIWE } from 'utils/siwe';
import { useClientContext } from './client';
import { useUserStore } from 'stores';
import { RPC_URLS } from 'config';

// Get projectId from https://cloud.reown.com
const projectId = WALLETCONNECT_PROJECT_ID;

const metadata = {
  name: 'Infinex Patron Portal',
  description: 'Patron Portal',
  url: ORIGIN_URL, // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/179229932'],
};

const networks: [AppKitNetwork, ...AppKitNetwork[]] = [
  EVM_CHAIN_ENV === 'testnet' ? sepolia : mainnet,
];

const chainId = EVM_CHAIN_ENV === 'testnet' ? 11155111 : 1;
const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
  transports: {
    [chainId]: http(RPC_URLS['ethereum']),
  },
  ssr: true,
});

export function AppKitProvider({ children }: PropsWithChildren) {
  const { client } = useClientContext();
  const userStore = useUserStore();

  async function onSignInCallback() {
    await userStore.fetchSession(client);
  }

  // Create a SIWE configuration object
  const siweConfig = createSIWE(networks, client, userStore, onSignInCallback);

  // Create modal
  createAppKit({
    adapters: [wagmiAdapter],
    networks,
    projectId,
    siweConfig,
    metadata,
    features: {
      email: false,
      socials: false,
      analytics: true, // Optional - defaults to your Cloud configuration
      swaps: false,
      onramp: false,
    },
  });

  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>{children}</WagmiProvider>
  );
}
