import { LockIcon, ProgressCompleteIcon } from '@infinex/ui/icons';
import { VestingItem } from './index';
import { Button } from '@infinex/ui/components';
import { cn } from '@infinex/ui/utils';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from 'react-aria-components';
import { formatNumber } from 'utils/utils';
import { useClaim } from 'hooks';
import { useState } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export default function ClaimPatronTable({
  vestingItems,
}: {
  vestingItems: VestingItem[];
}) {
  const { claimPatrons } = useClaim();
  const [loadingTier, setLoadingTier] = useState<number | null>(null);

  const isClaimingEnabled = useFeatureFlagEnabled('claim-patron-dapp') ?? false;

  const handleClaim = async (tierNumber: number) => {
    await claimPatrons(tierNumber, setLoadingTier);
  };

  return (
    <Table
      aria-label="Vesting Schedule Log"
      className="hidden w-full border-collapse overflow-hidden whitespace-nowrap sm:block [&_td:not(:last-child)]:pr-10 [&_th:not(:last-child)]:pr-10"
    >
      <TableHeader className="text-subtle font-condensed text-xs font-medium uppercase tracking-[1.1px]">
        <Column isRowHeader className="w-1/5 py-3 text-left uppercase">
          Tier
        </Column>
        <Column className="w-1/5 py-3 text-right">Total</Column>
        <Column className="w-1/5 py-3 text-right">Claimed</Column>
        <Column className="w-1/5 py-3 text-right">Available to Claim</Column>
        <Column className="w-1/5 py-3 text-right" />
      </TableHeader>
      <TableBody
        items={vestingItems}
        className="text-default divide-subtle title-sm-medium divide-y text-sm"
      >
        {(vestingItem) => {
          const isAllClaimed =
            vestingItem.claimed === vestingItem.total &&
            vestingItem.claimed > 0;
          const isLocked = vestingItem.tier === 'locked';

          return (
            <Row className="text-slate11 h-12 text-sm">
              <Cell className="align-middle">{vestingItem.tier}</Cell>
              <Cell className="text-right align-middle">
                {formatNumber(vestingItem.total)}
              </Cell>
              <Cell
                className={cn(
                  'text-subtle text-right align-middle',
                  vestingItem.claimed > 0 && 'text-accentDark'
                )}
              >
                {formatNumber(vestingItem.claimed)}
              </Cell>
              <Cell
                className={cn(
                  'text-subtle text-right align-middle',
                  vestingItem.available_to_claim > 0 && 'text-[#70FF93]'
                )}
              >
                {formatNumber(vestingItem.available_to_claim)}
              </Cell>
              <Cell className="py-3 text-right align-middle">
                {isAllClaimed ? (
                  <div className="text-subtle title-xs-medium flex items-center gap-x-1">
                    All patrons claimed
                    <ProgressCompleteIcon className="size-4" />
                  </div>
                ) : isLocked ? (
                  <div className="text-subtle title-xs-medium flex items-center gap-x-1">
                    Locked until oct '25
                    <LockIcon className="size-4" />
                  </div>
                ) : vestingItem.available_to_claim > 0 ? (
                  <Button
                    size="medium"
                    prominence="high"
                    isPending={loadingTier === vestingItem.tier_number}
                    onPress={() => handleClaim(vestingItem.tier_number)}
                    isDisabled={!isClaimingEnabled}
                  >
                    Claim
                  </Button>
                ) : (
                  <div className="text-subtle title-xs-medium">-</div>
                )}
              </Cell>
            </Row>
          );
        }}
      </TableBody>
    </Table>
  );
}
